import { Button, Popover, Row } from "antd";
import { FC, ReactNode, useEffect, useState } from "react";
import { v4 } from "uuid";
import { JobSchema } from "../types/JobSchema";
import { NewRunModal } from "./NewRunModal";
import { useTranslation } from "react-i18next";
import { BellOutlined } from "@ant-design/icons";

export interface JobTableHeaderProps<T extends JobSchema> {
  onRunStart: (schema: T) => void;
  children?: ReactNode;
  newRunModalComponent?: FC;
  requestNotification?: boolean;
  disableNewRun?: boolean;
}

export const JobTableHeader = <T extends JobSchema>({
  children,
  newRunModalComponent,
  onRunStart,
  requestNotification = false,
  disableNewRun = false,
}: JobTableHeaderProps<T>) => {
  const [modalKey, setModalKey] = useState<string>();
  const { t } = useTranslation("tools", {
    keyPrefix: "aggregate.remoteJobs",
  });
  const Modal = newRunModalComponent || NewRunModal;

  const openModal = () => {
    setModalKey(v4());
  };

  return (
    <Row justify="space-between" style={{ marginBottom: ".5rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button onClick={openModal} type="primary" disabled={disableNewRun}>
          {t("startNewRun")}
        </Button>
      </div>
      {requestNotification && <RequestNotification />}
      {children}
      <Modal<T>
        footer={<></>}
        key={modalKey}
        onCancel={() => setModalKey("")}
        onRunStart={(schema) => {
          onRunStart(schema);
          setModalKey("");
        }}
        open={!!modalKey}
      />
    </Row>
  );
};

const RequestNotification = () => {
  const [notificationSupport, setNotificationSupport] = useState<"none" | "default" | "denied" | "granted">("none");

  useEffect(() => {
    // If no notification support is detected at all, use "none".
    setNotificationSupport(typeof Notification === "undefined" ? "none" : Notification.permission);
  }, []);

  const requestPermission = () => {
    Notification.requestPermission(() => {
      setNotificationSupport(Notification.permission);
    });
  };

  const DeniedPopoverContent = () => (
    <>
      You have explicitly denied browser notifications.
      <br />
      <a
        href="https://support.humblebundle.com/hc/en-us/articles/360008513933-Enabling-and-Disabling-Browser-Notifications-in-Various-Browsers"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "underline" }}
      >
        Click here for support.
      </a>
    </>
  );

  const Tag = ({ text }: { text: string }) => {
    return (
      <div className="flex items-center gap-1 text-[#374151]">
        <BellOutlined />
        <span>{text}</span>
      </div>
    );
  };

  return (
    <>
      {notificationSupport === "none" && (
        <Popover content="Your browser does not support notifications." trigger="hover" placement="left">
          <Tag text="Error" />
        </Popover>
      )}

      {notificationSupport === "default" && (
        <Popover content="Enable Notifications" trigger="hover" placement="left">
          <Button icon={<BellOutlined />} onClick={requestPermission} />
        </Popover>
      )}

      {notificationSupport === "granted" && (
        <Popover
          content="You will receive a notification after your portfolio analysis is complete."
          trigger="hover"
          placement="left"
        >
          <Tag text="Enabled" />
        </Popover>
      )}

      {notificationSupport === "denied" && (
        <Popover title="Notification denied" content={<DeniedPopoverContent />} trigger="hover" placement="left">
          <Tag text="Denied" />
        </Popover>
      )}
    </>
  );
};
