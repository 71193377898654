import { CsvDragger } from "@/components/inputs/CsvDragger";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OmUploadData } from "../types/om";
import { RcFile } from "antd/es/upload";
import { getLineCountOfStream } from "@/utils/streams";

interface OmDraggerProps {
  onDataChange: (data?: OmUploadData) => void;
  data?: OmUploadData;
  prompt?: string;
}

/**
 * Allows a user to upload a CSV file full of OMs.
 * @returns Component.
 */
export const OmDragger = ({ data: givenData, onDataChange, prompt }: OmDraggerProps) => {
  const [data, setData] = useState<OmUploadData | undefined>(undefined);
  const { t } = useTranslation("widgets", {
    keyPrefix: "upload.om",
  });

  useEffect(() => {
    setData(givenData);
  }, [givenData]);

  useEffect(() => {
    onDataChange(data);
  }, [data]);

  return (
    <div>
      {data && (
        <div>
          {data.file.name} - {data.lineCount} lines
        </div>
      )}
      {!data && (
        <CsvDragger
          beforeUpload={async (file: RcFile) => {
            const lineCount = await getLineCountOfStream(file.stream());

            setData({ file, lineCount });

            // Avoid default behaviour.
            return false;
          }}
          onUpload={(result) => console.log(result)}
          prompt={prompt || t("prompt")}
        />
      )}
    </div>
  );
};
