import { RiskBandsAdvancedSettingsValues } from "@/tools/risk-bands/types";
import { Rcp, rcpValues } from "@/types/rcp";
import { GeocodeWithResult } from "../components/MultipleResultsTable";
import { downloadCsvFile } from "@/features/custom_downloads";
import { getArchetypes } from "@/features/branding";
import { anaHazards } from "@/lib/crg_apis/constants/hazards";
import { getTranslatedText } from "@/features/internationalisation";
import { limitLatLngTo6dp } from "@/utils/geocoding";
import { ensureGradedRiskValue } from "@/features/risk_bands";

/**
 * Triggers a .csv download of a list of assets with Risk Bands data.
 */
export const downloadRiskBandsCsv = ({ advancedSettings, filename, results }: {
  advancedSettings: Partial<RiskBandsAdvancedSettingsValues>;
  filename: string;
  results: GeocodeWithResult[];
}) => {
  const headers = [
    "Provided Address",
    "Geocode Quality",
    "Latitude",
    "Longitude",
    "Build Year",
    "Height Above Ground",
    "Archetype",
    "Scenario",
    "Year",
    "Risk Band",
  ];

  const isWordy = results.length && results[0].result && results[0].result.mode
    ? results[0].result.mode !== "abc"
    : false;

  if (isWordy) {
    headers.push("Significant Hazard");
  }

  const archetypeLabel = (archetype: string) => getArchetypes().find((a) => a.value === archetype)?.name;

  const body = results.reduce((acc, { geocode, result }) => {
    const currentRcps = Object.keys(result).filter((rcp) => rcpValues.includes(rcp as Rcp));

    const latLng = limitLatLngTo6dp(geocode.latLng);

    return [
      ...acc,
      ...currentRcps.flatMap((rcp) => {
        const common = [
          geocode.input,
          geocode.quality,
          latLng.lat,
          latLng.lng,
          advancedSettings.buildYear,
          advancedSettings.heightAboveGround?.amount,
          archetypeLabel(advancedSettings.archetype || "") || "unknown",
          getTranslatedText(`terms:scenarios.${rcp}.medium`),
        ];
        if (result.mode !== "abc") {
          return result[rcp as "rcp85"].ratings.map((rating) => {
            const hazards = result[rcp as "rcp85"].top_significant_hazards
              .map((h) => getTranslatedText(`terms:hazards.${anaHazards[h].newId}`))
              .join(", ");

            return [...common, rating.year, ensureGradedRiskValue(rating.rating!), hazards];
          });
        } else {
          const { current_year, end_of_century, end_of_mortgage_term } = result.rcp85;
          return [
            [...common, current_year.year, ensureGradedRiskValue(current_year.rating!)],
            [...common, end_of_mortgage_term.year, ensureGradedRiskValue(end_of_mortgage_term.rating!)],
            [...common, end_of_century.year, ensureGradedRiskValue(end_of_century.rating!)],
          ];
        }
      }),
    ];
  }, [] as any);

  downloadCsvFile([headers, ...body], {
    filename,
    prefixDate: true,
  });
};