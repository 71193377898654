import { formatReadableDateTime } from "@/utils/datetime";
import { Col, Row, Typography } from "antd";
import { JobSchema } from "../types/JobSchema";

const { Text } = Typography;

interface JobInformationProps<T extends JobSchema> {
  schema: Required<T>;
}

export const JobInformation = <T extends JobSchema>({ schema }: JobInformationProps<T>) => {
  const startTimeText = schema.startTime > 0 ? `${formatReadableDateTime(new Date(schema.startTime))}` : "Unavailable";

  return (
    <Col span={24}>
      <Row>{schema.name}</Row>
      <Row>
        <Text type="secondary">Started: {startTimeText}</Text>
      </Row>
    </Col>
  );
};
