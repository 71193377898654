import { HaloRiskValue } from "@/store/services/facades/types/halo";
import { SimpleCommercialRiskValue } from "@/store/services/facades/types/simple";
import { z } from "zod";

export type AbcdeRiskValue = "A" | "B" | "C" | "D" | "E";
export type WordyRiskValue = HaloRiskValue | SimpleCommercialRiskValue | SimpleCommercialRiskValue;

/**
 * Monetary values concerning an asset.
 */
export const inputValuesSchema = z.object({
  marketValue: z.number(),
  replacementCost: z.number(),
  mortgageRate: z.number(),
});
export type InputValuesConfig = z.infer<typeof inputValuesSchema>;

const archetypeSchema = z.object({
  id: z.string(),
  name: z.string(),
  value: z.string(),
  defaultInputValues: inputValuesSchema,
  category: z.enum(["residential", "commercial", "industrial"]),
  description: z.string().optional(),
  defaultHeightAboveGround: z.number().optional(),
  warning: z.string().optional(),
});
export type ArchetypeConfig = z.infer<typeof archetypeSchema>;

const baseSchema = z.object({
  archetypes: z.array(archetypeSchema),
  quickBatch: z.object({
    maxAddresses: z.number(),
  }),
});

export const abcRiskBandsSchema = baseSchema.extend({
  mode: z.literal("abc"),
});
export type AbcRiskBandsConfig = z.infer<typeof abcRiskBandsSchema>;

export const haloRiskBandsSchema = baseSchema.extend({
  mode: z.literal("halo"),
});
export type HaloRiskBandsConfig = z.infer<typeof haloRiskBandsSchema>;

export const simpleRiskBandsSchema = baseSchema.extend({
  mode: z.literal("simple"),
});
export type SimpleRiskBandsConfig = z.infer<typeof simpleRiskBandsSchema>;

export const riskBandsSchema = z.discriminatedUnion("mode", [abcRiskBandsSchema, haloRiskBandsSchema, simpleRiskBandsSchema]);
export type RiskBandsConfig = z.infer<typeof riskBandsSchema>;