import { AssignedColumn } from "@/features/csv_setup_table";
import { geocodeAddress, HubGeocoderKey, HubGeocoderResult } from "@/features/geocoders";
import { getConfig } from "@/features/branding";
import { LatLngLiteral } from "leaflet";

interface GeocodeAddressesResponse {
  results: HubGeocoderResult[];
  unmatched: string[];
}

// If a valid coordinate string, converts to LatLng, else returns null.
export const convertStringToLatLng = (input: string): LatLngLiteral | null => {
  const segments = input.split(",");
  if (segments.length !== 2) {
    return null;
  }

  const lat = parseFloat(segments[0].trim());
  const lng = parseFloat(segments[1].trim());

  return isNaN(lat) || isNaN(lng)
    ? null
    : { lat, lng };
};

export const extractAddressesFromColumnsAndData = (columns: AssignedColumn[], data: string[][]): string[] => {
  const addressIndex = columns.findIndex(({ option }) => option.value === "address");
  return data.slice(1).map((row) => row[addressIndex]);
};

// Geocode all addresses via given geocoders.
export const geocodeAddresses = async (
  addresses: string[],
  geocoders: HubGeocoderKey[] = getConfig().geocoding.defaultOrder as any
): Promise<GeocodeAddressesResponse> => {
  const results = await Promise.all(
    addresses.map((address, index) => {
      return new Promise<HubGeocoderResult>((res) => {
        window.setTimeout(async () => {
          res((await geocodeAddress(address, geocoders)) as HubGeocoderResult);
        }, 250 * index);
      });
    })
  );

  return results.reduce<GeocodeAddressesResponse>(
    (obj, result, index) => {
      return result
        ? { ...obj, results: [...obj.results, result] }
        : { ...obj, unmatched: [...obj.unmatched, addresses[index]] };
    },
    {
      results: [],
      unmatched: [],
    }
  );
};

export const limitLatLngTo6dp = (latLng: LatLngLiteral) => {
  return {
    lat: latLng.lat.toFixed(6),
    lng: latLng.lng.toFixed(6),
  };
};

/**
 * Evaluates whether or not a given latitude is valid.
 * @param lat - Latitude to validate.
 * @returns Validity of latitude.
 */
export const validateLatitude = (lat: number): boolean => {
  const invalid = isNaN(lat) || lat < -90 || lat > 90;
  return !invalid;
};

/**
 * Evaluates whether or not a given longitude is valid.
 * @param lng - Longitude to validate.
 * @returns Validity of longitude.
 */
export const validateLongitude = (lng: number): boolean => {
  const invalid = isNaN(lng) || lng < -180 || lng > 180;
  return !invalid;
};
