import { PortfolioExportRawSchema } from "@/tools/aggregate/portfolio-export/schema";
import { parse } from "papaparse";

/**
 *
 * @param line -
 * @param header -
 * @returns
 */
export const parsePortfolioExportUploadLine = (line: string, header: string): PortfolioExportRawSchema => {
  const { data } = parse<PortfolioExportRawSchema>([header.toString(), line.toString()].join("\n"), {
    header: true,
    dynamicTyping: true,
  });
  return data[0];
};
