import { CommsModal } from "@/components/overlays/CommsModal";
import { getCommsConfig } from "@/features/branding";

export type CommsModalControllerMode = "none" | "support" | "feedback";

export interface CommsModalControllerProps {
  mode: CommsModalControllerMode;
  onClose: () => void;
}

export const CommsModalController = ({ mode, onClose }: CommsModalControllerProps) => {
  const { feedback, support } = getCommsConfig();

  return (
    <>
      {feedback.enabled && <CommsModal mode="feedback" open={mode === "feedback"} onCancel={onClose} />}
      {support.enabled && <CommsModal mode="support" open={mode === "support"} onCancel={onClose} />}
    </>
  );
};
