import { LoadingOutlined } from "@ant-design/icons";
import styles from "@/components/overlays/Loading.module.scss";
import classNames from "classnames";

export interface LoadingProps {
  isOpaque?: boolean;
}

export const Loading = ({ isOpaque }: LoadingProps) => {
  const classes = classNames(styles.container, {
    [styles["container--opaque"]]: isOpaque,
  });

  return (
    <div className={classes}>
      <LoadingOutlined className={styles.icon} />
    </div>
  );
};
