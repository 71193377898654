import { ConfigProvider } from "antd";
import { getTheme } from "@/features/branding";
import { useTranslation } from "react-i18next";
import en_GB from "antd/locale/en_GB";
import zh_CN from "antd/locale/zh_CN";
import zh_HK from "antd/locale/zh_HK";

const locales = {
  "en-AU": en_GB,
  "zn-CN": zh_CN,
  "zh-HK": zh_HK,
};

export const CustomConfigProvider = ({ children }: { children: any }) => {
  const { i18n } = useTranslation();

  const locale = i18n.language as keyof typeof locales;

  return (
    <ConfigProvider locale={locales[locale]} theme={getTheme().antd}>
      {children}
    </ConfigProvider>
  );
};
