import { RawCsvData } from "@/features/csv_setup_table";
import { getTheme } from "@/features/branding";
import { CloudUploadOutlined } from "@ant-design/icons";
import Upload, { DraggerProps } from "antd/lib/upload";
import { DefaultTFuncReturn } from "i18next";
import { parse } from "papaparse";
import { useTranslation } from "react-i18next";

const { Dragger } = Upload;

export interface CsvDraggerProps extends DraggerProps {
  onUpload: (result: RawCsvData) => void;
  previewLineCount?: number;
  prompt?: string | DefaultTFuncReturn;
}

/**
 * Accepts a .csv upload from the user.
 */
export const CsvDragger = ({ onUpload, previewLineCount = 10, prompt, ...overrides }: CsvDraggerProps) => {
  const theme = getTheme();
  const { t } = useTranslation("widgets", {
    keyPrefix: "upload.csv",
  });

  const draggerProps: DraggerProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    showUploadList: false,
    beforeUpload: (file) => {
      const decoder = new TextDecoder();
      const stream = file.stream();
      const reader = stream.getReader();

      // We only need at most the first 11 lines for preview (a header and 10 data rows).
      // TODO: Spill over to multiple chunk "read()"s to ensure we get all data.
      reader.read().then((value) => {
        const data = decoder.decode(value.value);
        const lines = data
          .split("\n")
          .slice(0, previewLineCount + 1)
          .join("\n");
        onUpload(parse(lines).data as RawCsvData);
      });

      // Prevents default upload process.
      return false;
    },
    ...overrides,
  };

  return (
    <Dragger style={{ fontFamily: theme.antd.token?.fontFamily }} {...draggerProps}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined
          style={{
            opacity: 0.666,
            color: theme.antd.token?.colorPrimary,
          }}
        />
      </p>
      <p className="ant-upload-hint">{prompt || t("prompt")}</p>
    </Dragger>
  );
};
