import { FeatureCollection, AllGeoJSON, featureCollection, Feature, feature, Geometry } from "@turf/turf";

/**
 * Accepts any valid GeoJSON object and converts it to a FeatureCollection.
 * @param geojson - Any acceptable GeoJSON object.
 * @returns FeatureCollection.
 */
export const convertGeoJsonToFeatureCollection = (geojson: AllGeoJSON): FeatureCollection => {
  switch (geojson.type) {
    case "FeatureCollection":
      return geojson as FeatureCollection;

    case "Feature":
      return featureCollection([geojson as Feature]) as FeatureCollection;

    default:
      return featureCollection([feature(geojson as Geometry)]) as FeatureCollection;
  }
};