import _default from "./_default";
import { HubTheme } from "../types";
import { merge } from "lodash/fp";

/**
 * The theme is copied by Webpack to ./_webpack. We have to get unsafe here
 * because this folder may not exist when linting on a clean build.
 */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import theme from "./_webpack";

export const allThemes: Record<string, HubTheme> = {
  _default,
  [process.env.NEXT_PUBLIC_THEME_NAME!]: merge(_default, theme),
};
