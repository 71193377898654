import { RemoteJobTable } from "@/features/remote_jobs";
import { store } from "@/store";
import { supabaseApi, useGetPortfoliosQuery } from "@/store/services/supabase";
import { portfolioExportActions } from "@/store/slices/portfolioExport";
import { PortfolioExportProgressTracker } from "@/tools/aggregate/portfolio-export/classes/PortfolioExportProgressTracker";
import { PortfolioExportJobTableBody } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobTableBody";
import { PortfolioExportMaxSize } from "@/tools/aggregate/portfolio-export/components/PortfolioExportMaxSize";
import { PortfolioExportTableHeader } from "@/tools/aggregate/portfolio-export/components/PortfolioExportTableHeader";
import { PortfolioPasswordInput } from "@/tools/aggregate/portfolio-export/components/PortfolioPasswordInput";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { Tool, ToolProps } from "@/types/tools";
import { useKeyPair } from "@/utils/hooks/useKeyPair";

import { decryptData } from "@/features/cryptography";
import { PortfolioExportTutorial } from "@/tools/aggregate/portfolio-export/components/PortfolioExportTutorial";
import { ProjectOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { isEqual } from "lodash";
import { FC, useEffect, useRef, useState } from "react";

const PortfolioExportTool = (props: ToolProps) => {
  const portfolios = useGetPortfoliosQuery();
  const [progressTracker, setProgressTracker] = useState<PortfolioExportProgressTracker>();
  const [error, setError] = useState<null | { message: string; description: string[] }>(null);
  const [jobSchemas, _setJobSchemas] = useState<PortfolioExportJobSchema[]>([]);
  const [isJobSchemasLoading, setJobSchemasLoading] = useState(true);
  const refJobSchemas = useRef<PortfolioExportJobSchema[]>();
  const refProgressTracker = useRef<PortfolioExportProgressTracker>();
  const [keyPair] = useKeyPair();

  const keyPairNotPresent = !keyPair.isLoading && !keyPair.user;

  useEffect(() => {
    props.setIsTutorialDisabled(keyPairNotPresent);
  }, [keyPairNotPresent]);

  /** This ensures that we don't have to wait until state update to get updated value. */
  refJobSchemas.current = jobSchemas;
  refProgressTracker.current = progressTracker;

  /** Wraps _setJobSchemas to ensure the progress tracker is kept up to date. */
  const setJobSchemas = (schemas: PortfolioExportJobSchema[]) => {
    _setJobSchemas(schemas);

    if (refProgressTracker.current) {
      refProgressTracker.current.schemas = schemas;
    }
  };

  useEffect(() => {
    if (!keyPair.user) return;

    const tracker = new PortfolioExportProgressTracker(updateSchema, keyPair.user);
    setProgressTracker(tracker);
  }, [keyPair.user]);

  useEffect(() => {
    if (!progressTracker) return;

    progressTracker.schemas = jobSchemas;
    progressTracker.progress().catch((err) => {
      if (err.name === "AbortError") return;
      console.error("Something went wrong in progressTracker: ", err);
      setError(err);
    });
    setJobSchemasLoading(false);
  }, [jobSchemas, progressTracker]);

  useEffect(() => {
    if (!keyPair.user) return;

    updateSchemas();
  }, [portfolios, keyPair.user]);

  /**
   * Update a specific schema.
   */
  const updateSchema = (updatedSchema: PortfolioExportJobSchema) => {
    let hasUpdated = false;

    const schemas = (refJobSchemas.current || jobSchemas).map((schema) => {
      if (schema.id === updatedSchema.id) {
        hasUpdated = !isEqual(schema, updatedSchema);
        return updatedSchema;
      }
      return schema;
    });

    hasUpdated && setJobSchemas(schemas);
  };

  /**
   * Matches schemas to new portfolio data.
   */
  const updateSchemas = async () => {
    if (portfolios.data === undefined) {
      return;
    }

    const promises = portfolios.data.map(async (item) => {
      const existingSchema = (refJobSchemas.current || jobSchemas).find(({ id }) => id === item.id);

      const assets = {
        errorCount: item.error || 0,
        totalCount: item.total || 0,
        processedCount: item.success || 0,
        unprocessedCount: item.unprocessed || 0,
      };

      const getStatus = async () => {
        if (item.pending === 0 && item.error) return "completed_with_errors";
        if (item.pending === 0) return "completed";

        if (
          item.total === (item.success || 0) + (item.error || 0) &&
          item.total > 0 &&
          (item.errors_inserted || 0) === (item.error || 0)
        ) {
          return "completed";
        }

        if (existingSchema?.status) return existingSchema.status;

        if (assets.unprocessedCount) {
          store.dispatch(
            portfolioExportActions.setNewRunTotalInBatchTo({
              id: item.id!,
              count: assets.unprocessedCount,
            })
          );
          return "uploading";
        }

        return "processing";
      };

      const schema = {
        id: item.id!,
        name: item.name ? await decryptData(keyPair.user!.keyPair, item.name) : "Portfolio Export",
        assets,
        startTime: new Date(item.created_at!).getTime(),
        status: await getStatus(),
        summaryId: item.summary_id || "",
      };

      return schema;
    });

    const schemas: PortfolioExportJobSchema[] = await Promise.all(promises);
    setJobSchemas(schemas);
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("hkma") && <PortfolioExportMaxSize />}

      {error && (
        <Alert
          message={error.message || "An unexpected error has occurred"}
          description={
            error.description ? (
              <ul>
                {error.description.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              "Please refresh your browser & try again. If the error persists, please contact support."
            )
          }
          type="error"
          closable
        />
      )}
      {keyPairNotPresent ? (
        <PortfolioPasswordInput />
      ) : (
        <RemoteJobTable<PortfolioExportJobSchema>
          headerComponent={PortfolioExportTableHeader as FC}
          jobSchemas={jobSchemas}
          onRunStart={console.log}
          setJobSchemas={setJobSchemas}
        >
          <PortfolioExportJobTableBody
            schemas={jobSchemas}
            loading={isJobSchemasLoading}
            updateSchema={updateSchema}
            onDelete={async (id: string) => {
              const action = supabaseApi.endpoints.deletePortfolio.initiate(id);
              await store.dispatch(action);

              const schemas = refJobSchemas.current || jobSchemas;
              setJobSchemas(schemas.filter((schema) => id !== schema.id));
            }}
            onError={async (err, portfolioId) => {
              const action = supabaseApi.endpoints.deletePortfolio.initiate(portfolioId);
              await store.dispatch(action);
              const schemas = refJobSchemas.current || jobSchemas;
              setJobSchemas(schemas.filter((schema) => portfolioId !== schema.id));

              setError({
                message: "Error while uploading your portfolio",
                description: [...err],
              });
            }}
          />
        </RemoteJobTable>
      )}
      <PortfolioExportTutorial
        onClose={() => props.setIsTutorialOpen(false)}
        open={props.isTutorialOpen}
        setState={
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {}
        }
      />
    </>
  );
};

export default {
  id: "portfolio-export",
  category: "aggregate",
  keyPrefix: "aggregate.portfolioExport",
  icon: <ProjectOutlined />,
  render: (props: ToolProps) => <PortfolioExportTool {...props} />,
  hasTutorial: true,
} as Tool;
