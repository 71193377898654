import { unwrapEncryptedKeyPair, importKey, exportKey } from "@/features/cryptography";
import { arrayBufferToString, stringToArrayBuffer } from "@/utils/crypto";
import { KeyPairStore, KeyPairWithSalt, KeyPairWithSaltString } from "@/utils/hooks/useKeyPair/types";

type UnwrapKeyPairFnType = { data: KeyPairWithSaltString; password: string };
export const unwrapKeyPair = async ({ data, password }: UnwrapKeyPairFnType) => {
  const salt = stringToArrayBuffer(data.salt);
  const { privateKey, publicKey } = await unwrapEncryptedKeyPair(
    {
      privateKey: stringToArrayBuffer(data.keyPair.privateKey),
      publicKey: stringToArrayBuffer(data.keyPair.publicKey),
    },
    password,
    salt
  );

  return {
    keyPair: {
      privateKey,
      publicKey,
    },
    salt,
  };
};

type GetKeysFromSessionStorageType = {
  public: string;
  private: string;
  salt: string;
};

export async function getKeysFromSessionStorage(keys: GetKeysFromSessionStorageType): Promise<any> {
  const keyPair = {
    public: sessionStorage.getItem(keys.public),
    private: sessionStorage.getItem(keys.private),
    salt: sessionStorage.getItem(keys.salt),
  };

  // console.log(keyPair)

  if (!keyPair.private || !keyPair.public || !keyPair.salt) {
    return null;
  }

  const publicKey = importKey(keyPair.public, ["encrypt"]);
  const privateKey = importKey(keyPair.private, ["decrypt"]);
  const salt = stringToArrayBuffer(keyPair.salt);

  const values = await Promise.all([privateKey, publicKey, salt]).then((values) => ({
    keyPair: {
      privateKey: values[0],
      publicKey: values[1],
    },
    salt: values[2],
  }));

  return values;
}

export async function setKeysInSessionStorage(keys: KeyPairStore, values: KeyPairWithSalt) {
  await exportKey(values.keyPair.privateKey).then((k) => sessionStorage.setItem(keys.private, JSON.stringify(k)));
  await exportKey(values.keyPair.publicKey).then((k) => sessionStorage.setItem(keys.public, JSON.stringify(k)));
  sessionStorage.setItem(keys.salt, arrayBufferToString(values.salt));
}
