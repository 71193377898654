import { Alert, Button, Divider, Input, Progress, Statistic, Upload } from "antd";
import s from "./PortfolioExportUploadReview.module.scss";
import { HacsOmUploadData } from "@/features/hacs";
import { useGetCurrentPortfolioExportUsageQuery } from "@/store/services/supabase";
import { useState } from "react";
import { removeFileExtension } from "@/utils/string";
import { ArrowUpOutlined } from "@ant-design/icons";
import { getConfig } from "@/features/branding";

// {"quota":100,"usage":95,"percent_used":95,"percent_left":5,"percent_limit":0,"newRun":{"enabled":true,"warning":"limit"}}

export interface PortfolioExportUploadReviewProps {
  /** Handles whenever a CSV is selected. */
  setData: (data?: HacsOmUploadData) => void;
  /** File data for the chosen CSV upload. */
  data: HacsOmUploadData;
  onBack: () => void;
  onContinue: () => void;
}

export const PortfolioExportUploadReview = ({
  data,
  setData,
  onBack,
  onContinue,
}: PortfolioExportUploadReviewProps) => {
  const { termsOfService } = getConfig().featureSet.docs.legal;

  const currentUsage = useGetCurrentPortfolioExportUsageQuery().data;
  const [filename, setFilename] = useState(removeFileExtension(data.file.name || ""));

  if (!currentUsage) return <></>;

  // Don't count the header row.
  const uploadedAssetCount = data.lineCount - 1;
  const uploadedAssetPercent = (uploadedAssetCount / currentUsage.quota) * 100;
  const futureUsage = currentUsage.usage + uploadedAssetCount;
  const futureUsagePercent = (futureUsage / currentUsage.quota) * 100;

  const showWarning = futureUsagePercent > 80 && futureUsagePercent <= 100;
  const showError = futureUsagePercent > 100;

  const handleContinue = () => {
    setData({ ...data, name: filename });
    onContinue();
  };

  return (
    <div className={s.fileUploadContainer}>
      <div className={s.fileContainer}>
        <Upload
          listType="text"
          disabled
          defaultFileList={[
            {
              uid: "0",
              status: "done",
              name: data.file.name,
            },
          ]}
        />
      </div>

      <fieldset className={s.fileContainer}>
        <legend>Review Upload</legend>
        <div className={s.statsContainer}>
          <div className={s.statsRowContainer}>
            <div className={s.statsTitle}>Name</div>
            <Input className={s.fileInfoName} value={filename} onChange={(val) => setFilename(val.target.value)} />
          </div>

          <div className={s.statsRowContainer} style={{ marginBottom: "2em" }}>
            <div className={s.statsTitle}>Total Assets</div>
            <Statistic value={uploadedAssetCount} className={s.statsValue} />
          </div>

          <Divider
            plain
            orientation="left"
            style={{
              margin: 0,
              marginBottom: "0.5em",
              color: "var(--color-gray-400)",
            }}
            orientationMargin={"0"}
          >
            Usage After Upload
          </Divider>

          <div className={s.statsRowContainer}>
            <div className={s.statsTitle}>Assets Used</div>
            <Statistic
              value={futureUsage}
              className={`${s.statsValue} ${showWarning && s.warning} ${showError && s.error}`}
              suffix={`/ ${currentUsage.quota}`}
            />
            <Statistic
              value={uploadedAssetCount}
              valueStyle={{ color: "var(--color-gray-400)" }}
              prefix={<ArrowUpOutlined />}
              className={s.statsValue}
            />
          </div>

          <div className={s.statsRowContainer}>
            <div className={s.statsTitle}>Assets Used %</div>
            <Statistic
              value={futureUsagePercent}
              precision={2}
              suffix="%"
              className={`${s.statsValue} ${showWarning && s.warning} ${showError && s.error}`}
            />
            <Statistic
              value={uploadedAssetPercent}
              precision={2}
              valueStyle={{ color: "var(--color-gray-400)" }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
              className={s.statsValue}
            />
          </div>

          <div className={s.statsRowContainer}>
            <Progress
              percent={futureUsagePercent}
              success={{ percent: currentUsage.percent_used, strokeColor: "#4B1553" }}
              className={`${s.progressContainer} ${showError && s.error}`}
              showInfo={false}
              style={{ gridColumn: "2 / 4" }}
            />
          </div>
        </div>
      </fieldset>

      {showWarning && (
        <Alert
          message={
            <div>
              Your organization is close to the limit of your allocation. Please contact your administrator.{" "}
              <a href={termsOfService} target="_blank" rel="noreferrer">
                Read More
              </a>
            </div>
          }
          type="warning"
          showIcon
          className={s.alert}
        />
      )}

      {showError && (
        <Alert
          message={
            <div>
              <div>
                <strong>Limit Reached</strong>
              </div>
              Your institution has reached the limit of its annual allocation. The quota will be renewed on the
              first day of the next calendar year. Please upload a smaller file or contact your administrator
              or the service desk.{" "}
              <a href={termsOfService} target="_blank" rel="noreferrer">
                Read More
              </a>
            </div>
          }
          type="error"
          showIcon
          className={s.alert}
        />
      )}

      <div className={s.actionButtons}>
        <Button type="text" onClick={onBack}>
          Back
        </Button>
        <Button
          type="primary"
          onClick={handleContinue}
          disabled={currentUsage.newRun.alwaysEnabled ? false : showError}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
