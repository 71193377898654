import { schema as hubspot } from "./apis/hubspot/types";
import { plausibleSchema as plausible } from "./apis/plausible/types";

import { z } from "zod";

export const configSchema = z.object({
  hubspot,
  plausible,
});

export type Config = z.infer<typeof configSchema>;
