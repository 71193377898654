import { Loading } from "@/components/overlays/Loading";
import { DetailedHTMLProps, IframeHTMLAttributes, ReactEventHandler, useEffect, useState } from "react";

type IFrameProps = DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>;

export const IFrame = (props: IFrameProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
  }, [props.src]);

  const onLoad: ReactEventHandler<HTMLIFrameElement> = () => {
    setIsLoaded(true);
  };

  return (
    <>
      <iframe
        itemType="text/html"
        width="100%"
        height="100%"
        allowFullScreen={true}
        frameBorder="0"
        title={props.title || "Embedded Frame"}
        {...props}
        onLoad={onLoad}
      />
      {!isLoaded && <Loading isOpaque={true} />}
    </>
  );
};
