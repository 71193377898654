import classNames from "classnames";
import { AbcdeRiskValue } from "../schema";
import styles from "./RiskBand.module.scss";

interface SingleLetterRiskBandProps {
  rating: AbcdeRiskValue | "?";
  size: number;
  colour: string;
}

export const SingleLetterRiskBand = ({ rating, size, colour }: SingleLetterRiskBandProps) => {
  let innerClass = styles["inner--single-large"];
  if (size < 75) {
    innerClass = styles["inner--single-medium"];
  }
  if (size < 50) {
    innerClass = styles["inner--single-small"];
  }
  if (size < 25) {
    innerClass = styles["inner--single-tiny"];
  }

  return (
    <div
      className={styles.outer}
      style={{
        background: colour,
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <div className={classNames(styles.inner, innerClass, styles["inner--light"])}>{rating}</div>
    </div>
  );
};
