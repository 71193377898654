import { RiskBandsRiskValue } from "@/store/services/facades/types";
import { AbcdeRiskValue, WordyRiskValue } from "../schema";

/**
 * Converts the given risk value to ABCDE, and does nothing if already ABC.
 * @param band - RiskBandsRiskValue
 * @returns A letter between A-E.
 */
export const ensureGradedRiskValue = (band: RiskBandsRiskValue | AbcdeRiskValue): AbcdeRiskValue => {
  switch (band) {
    // Short-circuit if an AbcdeRiskValue.
    case "A":
    case "B":
    case "C":
    case "D":
    case "E":
      return band;
    case "LowExpectedPremium":
      return "A";
    case "ModerateExpectedPremium":
      return "B";
    case "Uninsurable":
      return "C";
    case "UninsurableHighConcern":
      return "D";
    case "UninsurableExtremeConcern":
      return "E";
  }
};

/**
 * Converts the given risk value to Wordy, and does nothing if already so.
 * @param band - RiskBandsRiskValue
 * @returns One of five long risk value texts.
 */
export const ensureWordyRiskValue = (band: RiskBandsRiskValue | AbcdeRiskValue): WordyRiskValue => {
  switch (band) {
    // Short-circuit if a wordy value.
    case "LowExpectedPremium":
    case "ModerateExpectedPremium":
    case "Uninsurable":
    case "UninsurableHighConcern":
    case "UninsurableExtremeConcern":
      return band;
    case "A":
      return "LowExpectedPremium";
    case "B":
      return "ModerateExpectedPremium";
    case "C":
      return "Uninsurable";
    case "D":
      return "UninsurableHighConcern";
    case "E":
      return "UninsurableExtremeConcern";
  }
};