import { unparse } from "papaparse";
import type { Options } from "../types";
import { download } from "../utils";

export const csv = (content: (string | number | undefined | null)[][], options: Options) => {
  download(
    unparse(content),
    {
      includeBom: true,
      ...options,
    },
    "text/csv"
  );
};
