import type { AppProps } from "next/app";
import "@/features/internationalisation";
import "leaflet/dist/leaflet.css";
import "../styles/globals.css";

import { NextPage } from "next";
import Head from "next/head";
import { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import supabase from "@/features/supabase";
import { Session, SessionContextProvider, useSessionContext } from "@supabase/auth-helpers-react";

import { CustomConfigProvider } from "@/components/layouts/CustomConfigProvider";
import { HubLayout } from "@/components/layouts/HubLayout";
import { Loading } from "@/components/overlays/Loading";
import { store } from "@/store";
import { getAppName, getConfig, getTheme } from "@/features/branding";
import { internalApi } from "@/store/services/internal";
import i18n from "@/features/internationalisation";
import { KeyPairProvider } from "@/utils/hooks/useKeyPair/KeyPairProvider";

import "@fontsource-variable/jetbrains-mono";
import { HubPlausibleProvider } from "@/features/tracking";

const UserLoadingWrapper = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isLoading } = useSessionContext();

  return isLoading ? <Loading /> : children;
};

export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  initialSession: Session;
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: ReactNode) => <HubLayout>{page}</HubLayout>);

  /** Apply branding translation overrides */
  useEffect(() => {
    const language = i18n.language;
    const overrides: Record<string, any> = getTheme().translationOverrides?.[language] || {};

    for (const key of Object.keys(overrides)) {
      i18n.addResourceBundle(language, key, overrides[key], true, true);
    }
  }, []);

  useEffect(() => {
    if (getConfig().featureSet.docs.contextualElementTutorials.enabled) {
      store.dispatch(internalApi.endpoints.getCmsPosts.initiate());
    }
  });

  return (
    <>
      <Head>
        <title>{getAppName()}</title>
        <meta name="description" content="Your home of climate insight" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HubPlausibleProvider>
        <Provider store={store}>
          <CustomConfigProvider>
            <SessionContextProvider supabaseClient={supabase}>
              <KeyPairProvider>
                <UserLoadingWrapper>
                  <>{getLayout(<Component {...pageProps} />)}</>
                </UserLoadingWrapper>
              </KeyPairProvider>
            </SessionContextProvider>
          </CustomConfigProvider>
        </Provider>
      </HubPlausibleProvider>
    </>
  );
}

export default MyApp;
