import { KeyPairContext, KeyPairContextType } from "@/utils/hooks/useKeyPair/KeyPairProvider";
import { getFromDatabase } from "@/utils/hooks/useKeyPair/helper";
import { useContext } from "react";
import { KEY_PAIR_STORE } from "@/utils/hooks/useKeyPair/constants";
import { generateNewUserKeyPairs, unwrapEncryptedKeyPair } from "@/features/cryptography";
import { setKeysInSessionStorage } from "@/utils/hooks/useKeyPair/utils";
import { useAddKeyPairMutation } from "@/store/services/supabase";
import { stringToArrayBuffer } from "@/utils/crypto";
import { KeyPairWithSalt } from "@/utils/hooks/useKeyPair/types";

type GetFromDbFnType = (password: string) => Promise<{ data: KeyPairWithSalt | null; error: any }>;
type GetOrGenerateFnType = (password: string) => Promise<void>;
type GenerateFnType = (password: string) => Promise<KeyPairWithSalt>;

type UseKeyPairType = [
  KeyPairContextType,
  {
    getFromDb: GetFromDbFnType;
    getOrGenerate: GetOrGenerateFnType;
  },
];

export const useKeyPair = (): UseKeyPairType => {
  const [addKeyPair] = useAddKeyPairMutation();

  const context = useContext(KeyPairContext);
  if (context === undefined) {
    throw new Error("useKeyPair must be used within a KeyPairProvider");
  }

  const getFromDb: GetFromDbFnType = async (password) => {
    const { data, error } = await getFromDatabase({ password });

    if (data) {
      await setKeysInSessionStorage(KEY_PAIR_STORE.user, data);
      await context.refetch();
      return {
        data,
        error: null,
      };
    }

    return {
      data,
      error: error ? error : "Something went wrong. Please try again.",
    };
  };

  const generate: GenerateFnType = async (password: string) => {
    const pairs = await generateNewUserKeyPairs(password);

    const { keyPair, salt } = await addKeyPair({
      publicKey: pairs.encryptedKeyPair.publicKey,
      privateKey: pairs.encryptedKeyPair.privateKey,
      salt: pairs.salt,
    })
      .unwrap()
      .catch((err) => {
        console.error("generateKeyPair: ", JSON.stringify(err));
        throw new Error(err);
      });

    const privateKey = stringToArrayBuffer(keyPair.privateKey);
    const publicKey = stringToArrayBuffer(keyPair.publicKey);
    const saltAB = stringToArrayBuffer(salt);

    const unwrapped = await unwrapEncryptedKeyPair({ privateKey, publicKey }, password, saltAB);
    const data = {
      keyPair: unwrapped,
      salt: saltAB,
    };

    await setKeysInSessionStorage(KEY_PAIR_STORE.user, data);

    await context.refetch();

    return data;
  };

  const getOrGenerate: GetOrGenerateFnType = async (password: string) => {
    if (context.user) return;

    const res = await getFromDb(password).then((r) => r.data);

    if (!res) {
      await generate(password);
    }
  };

  return [
    context,
    {
      getFromDb,
      getOrGenerate,
    },
  ];
};
