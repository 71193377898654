import { ContextualInfoButton } from "@/components/buttons/ContextualInfoButton";
import { getTheme } from "@/features/branding";
import { HacsJobSchema } from "@/features/hacs";
import { logToolUsage } from "@/features/tracking";
import { BlazeJobTable } from "@/tools/aggregate/blaze/components/BlazeJobTable";
import { Tool } from "@/types/tools";
import { BookOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { FC, useState } from "react";
import { v4 } from "uuid";
import { PortfolioAnalysisNewRunModal } from "./components/PortfolioAnalysisNewRunModal";

interface RunsTableTitleProps {
  onRunStart: (schema: HacsJobSchema) => void;
}

const buildPortfolioAnalysisTool = (hazards?: string[]): Tool => {
  const RunsTableTitle = ({ onRunStart }: RunsTableTitleProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalKey, setModalKey] = useState<string>();

    const openModal = () => {
      setModalKey(v4());
      setIsModalOpen(true);
    };

    return (
      <Row justify="space-between" style={{ marginBottom: ".5rem" }}>
        <Button onClick={openModal} type="primary">
          Start New Run
        </Button>
        <ContextualInfoButton
          className=""
          id="portfolioAnalysisResultsExplanation"
          type="link"
          style={{
            color: getTheme().antd.token?.colorPrimary,
          }}
        >
          What do my results mean?
        </ContextualInfoButton>
        <PortfolioAnalysisNewRunModal
          footer={<></>}
          key={modalKey}
          hazards={hazards}
          onRunStart={(schema) => {
            onRunStart(schema);
            setIsModalOpen(false);
            logToolUsage({
              type: "portfolio_analysis",
              count: schema.lineCount,
            });
          }}
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
      </Row>
    );
  };

  const CommonPortfolioAnalysisComponent = () => {
    return (
      <BlazeJobTable
        failModeId="debugInfo"
        headerComponent={RunsTableTitle as FC}
        localStorageKey="commonPortfolioAnalysisRuns"
      />
    );
  };

  return {
    id: "portfolioAnalysis",
    category: "aggregate",
    keyPrefix: "aggregate.commonPortfolioAnalysis",
    icon: <BookOutlined />,
    render: () => <CommonPortfolioAnalysisComponent />,
  };
};

export default buildPortfolioAnalysisTool;
