import { KeyPair, Salt, KeyPairWithSalt } from "@/utils/hooks/useKeyPair/types";
import { PropsWithChildren, createContext, useEffect, useMemo, useState } from "react";
import { KEY_PAIR_STORE } from "@/utils/hooks/useKeyPair/constants";
import { getKeysFromSessionStorage } from "@/utils/hooks/useKeyPair/utils";

export type KeyPairContextType = {
  user:
    | {
        keyPair: KeyPair["keyPair"];
        salt: Salt["salt"];
      }
    | undefined;
  group?:
    | {
        keyPair: KeyPair["keyPair"];
        salt: Salt["salt"];
      }
    | undefined;
  isLoading: boolean;
  refetch: any;
};

export const KeyPairContext = createContext<KeyPairContextType>({
  user: undefined,
  group: undefined,
  isLoading: true,
  refetch: null,
});

export const KeyPairProvider = ({ children }: PropsWithChildren) => {
  const [userKeyPair, setUserKeyPair] = useState<KeyPairWithSalt>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function getKeyPair() {
      setIsLoading(true);
      if (mounted) {
        setUserKeyPair(await getKeysFromSessionStorage(KEY_PAIR_STORE.user));
        setIsLoading(false);
      }
    }

    getKeyPair();
    return () => {
      mounted = false;
    };
  }, []);

  const refetch = async () => {
    console.log("refetch");
    setUserKeyPair(await getKeysFromSessionStorage(KEY_PAIR_STORE.user));
  };

  const value = useMemo(() => {
    if (isLoading) {
      return {
        user: undefined,
        isLoading: true,
        refetch,
      };
    }

    if (!userKeyPair) {
      return {
        user: undefined,
        isLoading: false,
        refetch,
      };
    }

    return {
      user: {
        keyPair: userKeyPair.keyPair,
        salt: userKeyPair.salt,
      },
      isLoading: false,
      refetch,
    };
  }, [userKeyPair]);

  return <KeyPairContext.Provider value={value}>{children}</KeyPairContext.Provider>;
};

