import { LatLng } from "@/lib/crg_apis/types/latlng";
import { allGeocoders, HubGeocoderKey } from "@/features/geocoders";
import { getConfig } from "@/features/branding";
import { AutoComplete, AutoCompleteProps } from "antd";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface AddressLookupProps extends Omit<AutoCompleteProps, "onChange"> {
  address: string;
  onChange: (address: string, latlng: LatLng) => void;
  onClear: () => void;
  geocoder?: HubGeocoderKey;
}

export const AddressLookup = ({ address, onChange, onClear, geocoder, ...props }: AddressLookupProps) => {
  const [hasMounted, setHasMounted] = useState(false);
  const [input, setInput] = useState(address);
  const [results, setResults] = useState<any[]>([]);
  const { t } = useTranslation("widgets", {
    keyPrefix: "addressAutocomplete",
  });
  const config = getConfig();

  useEffect(() => setHasMounted(true), []);
  useEffect(() => setInput(address), [address]);

  useMemo(
    debounce(async () => {
      // useMemo can cause React state errors, so we have to ensure we're mounted.
      if (!hasMounted) {
        return;
      }

      if (!input || input.length < config.geocoding.minLengthForSuggestions || input === address) {
        setResults([]);
        return;
      }

      setResults(await autocompleter.getSuggestions(input));
    }, 500),
    [input]
  );

  const geocoderKey = geocoder || (getConfig().geocoding.defaultOrder[0] as HubGeocoderKey) || "google";
  const { autocompleter } = allGeocoders[geocoderKey];

  const handleClear = () => {
    onClear();
  };

  const handleSelect = async (name: string, data: any) => {
    const result = await autocompleter.suggestionToResult(data);

    if (!result) {
      return;
    }

    onChange(name, result.latLng);
  };

  return (
    <AutoComplete
      aria-label="Enter Asset Address"
      allowClear={true}
      className="text-left w-full"
      onBlur={() => setResults([])}
      onClear={handleClear}
      onChange={(value) => setInput(value)}
      onSelect={handleSelect}
      options={results}
      placeholder={t("prompt") || ""}
      value={input}
      {...props}
    />
  );
};
