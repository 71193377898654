import PortfolioExportAggregatedRiskIcon from "@/../public/img/icons/home/portfolio-export-aggregated-risk-icon.svg";
import PortfolioExportHighRiskIcon from "@/../public/img/icons/home/portfolio-export-high-risk-icon.svg";
import QuickBatchIcon from "@/../public/img/icons/home/quick-batch-icon.svg";
import SingleAssetIcon from "@/../public/img/icons/home/single-asset-icon.svg";
import { getConfig, getTools } from "@/features/branding";
import { AuthSession } from "@supabase/supabase-js";
import { HomeDashboardItem } from "./types";

interface HomeDashboardItemWithPrefixes extends HomeDashboardItem {
  toolKeyPrefix: string;
}

export const getDefaultHomeDashboardItems = (session: AuthSession | null): HomeDashboardItemWithPrefixes[] => {
  const { riskBands } = getConfig();
  const toolPrefixes = getTools(session).map((tool) => tool.keyPrefix);
  const quickBatchMaxAddresses = riskBands.quickBatch.maxAddresses;

  return [
    {
      title: (
        <>
          Understand risk
          <br /> to a single asset
        </>
      ),
      tool: "Single Asset Screen",
      link: "/tools/riskBands/single",
      icon: <SingleAssetIcon />,
      toolKeyPrefix: "riskBands.single",
    },
    {
      title: <>Compare risk to the same build type in up to {quickBatchMaxAddresses} different locations </>,
      tool: "Quick Batch Screen",
      link: "/tools/riskBands/batch",
      icon: <QuickBatchIcon />,
      toolKeyPrefix: "riskBands.batch",
    },
    {
      title: <>Understand aggregated risk across an entire portfolio </>,
      tool: "Portfolio Export",
      link: "/tools/aggregate/portfolio-export",
      icon: <PortfolioExportAggregatedRiskIcon />,
      toolKeyPrefix: "aggregate.portfolioExport",
    },
    {
      title: <>Identify high - risk properties within a portfolio </>,
      tool: "Portfolio Export",
      link: "/tools/aggregate/portfolio-export",
      icon: <PortfolioExportHighRiskIcon />,
      toolKeyPrefix: "aggregate.portfolioExport",
    },
  ].filter(({ toolKeyPrefix }) => toolPrefixes.indexOf(toolKeyPrefix) !== -1);
};
