import type { Tools } from "../../types/tools";

const tools: Tools = {
  meta: {
    title: "Welcome To The Hub",
    subtitle: "Please select a tool from the sidebar.",
    zeroToolsAccess: {
      subtitle: "You do not have access to tools at the moment. Please contact your administrator to get started.",
    },
    accessDenied: {
      title: "You don't have permission to access this tool",
      blurb:
        "If you have recently been granted access, please log out and then log back in. For access requests, kindly contact your administrator.",
    },
  },
  settings: {
    title: "Settings",
    archetype: {
      title: "Asset Type (Archetype)",
    },
    buildYear: {
      title: "Build Year",
    },
    heightAboveGround: {
      title: "Height Above Ground",
    },
  },
  admin: {
    title: "Administration",

    hubspotDraftTester: {
      title: "HubSpot Draft Tester",
      subtitle: "Try out HubSpot pages in the Hub before publishing.",
    },
    portfolioAnalysisGrabber: {
      title: "Portfolio Analysis Grabber",
      subtitle: "Retrieve files associated with a Common Portfolio Analysis run.",
    },
  },
  aggregate: {
    title: "Aggregate Analysis",

    blaze: {
      title: "Multi-Asset",
      subtitle: "Analyse and process large sets of assets.",
      steps: ["Provide File", "Customise", "Process Data", "View Results"],
    },
    portfolioExport: {
      title: "Portfolio Export",
      subtitle: "Analyse and process large sets of assets.",
      processingAlert: {
        title: "Please do not close your browser while your portfolio is processing.",
        blurb:
          "This typically takes a few minutes to a few hours, depending on your portfolio size. If processing exceeds 2 days, please reach out to the service desk.",
      },
    },
    commonPortfolioAnalysis: {
      title: "Portfolio Analysis",
      subtitle: "Process a .csv file containing up to 2000 assets.",
    },
    remoteJobs: {
      title: "Remote Jobs",
      subtitle: "Process data on remote servers.",
      job: "Job",
      startNewRun: "Start New Run",
    },
  },
  assetLevelDeepDive: {
    title: "Asset-LeveL Deep Dive",
  },
  marketIntelligence: {
    title: "Market Intelligence Tools",

    gdcr: {
      title: "Gross Domestic Climate Risk",
      subtitle: "",
    },
    mci: {
      title: "Multi Company Intelligence",
      subtitle: "",
    },
  },
  coordinateGeneration: {
    title: "Coordinate Generation",

    largeSiteGrid: {
      title: "Large Site Grid (Preview)",
      subtitle: "Generate coordinates from a polygon of a large site.",
    },
  },
  reference: {
    title: "Reference",

    database: {
      title: "Database",
      subtitle: "Browse or search our library to explore external datasets.",
    },
    riskMap: {
      title: "Risk Map (Draft)",
      subtitle: "See climate change risk by year.",
    },
  },
  riskBands: {
    title: "Simple Risk Bands",

    batch: {
      title: "Quick Batch Screen",
      subtitle: "Efficiently assess a short list of assets.",
      steps: ["Add Data", "Process data", "Results"],
      step2Steps: ["Geocode all addresses", "Obtain risk bands for all addresses"],
    },
    single: {
      title: "Single Asset Screen",
      subtitle: "Assess an asset's maximum calculable risk.",
    },
  },
};

export default tools;
