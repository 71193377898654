import { anaHazardList, anaHazards } from "@/lib/crg_apis/constants/hazards";
import { HubFormItem } from "@/components/inputs/HubFormItem";
import { getTranslatedText } from "@/features/internationalisation";
import { Button, Col, Row, Select } from "antd";
import { AnaHazardId } from "@/store/services/facades/types";

interface HazardsSelectorProps {
  hazards: AnaHazardId[];
  onChange: (hazards: AnaHazardId[]) => void;
}

/**
 * Allow multiple selection of hazards.
 * @returns Components.
 */
export const HazardsSelector = ({ hazards, onChange }: HazardsSelectorProps) => {
  return (
    <>
      <HubFormItem
        label={
          <Row style={{ width: "100%" }}>
            <Col flex={1}>Requested Hazards</Col>
            <Col>
              <Button type="link" style={{ height: "auto", padding: 0, margin: 0 }}>
                Select All
              </Button>
            </Col>
          </Row>
        }
      >
        <Select
          defaultValue={hazards}
          mode="multiple"
          onChange={onChange}
          options={anaHazardList.map((value) => ({
            value,
            label: getTranslatedText(`terms:hazards.${anaHazards[value].newId}`),
          }))}
        />
      </HubFormItem>
    </>
  );
};
