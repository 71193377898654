import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useSession } from "@supabase/auth-helpers-react";
import { Button, Form, Input, Skeleton } from "antd";
import { useState } from "react";
import styles from "./UserPasswordInput.module.scss";

const UserKeyPair = ({ prompt = "Enter your password to decrypt your portfolio" }: { prompt?: string }) => {
  const session = useSession();
  const [, { getFromDb }] = useKeyPair();
  const [error, setError] = useState("");

  const handlePasswordSubmit = async ({ password }: { password: string }) => {
    const { error } = await getFromDb(password);
    error && setError(error);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.skeletonWrapper}>
          {Array.from({ length: 4 }).map((_, idx) => (
            <Skeleton active key={idx} />
          ))}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            <LockOutlined />
          </div>
          <div style={{ lineHeight: 1.5 }}>{prompt}</div>
          <br />
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={handlePasswordSubmit}
            fields={[
              {
                name: ["email"],
                value: session?.user.email,
              },
            ]}
            onBlur={() => setError("")}
          >
            <Form.Item name="email">
              <Input name="email" prefix={<UserOutlined />} disabled={true} />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: "Password is required" }]}>
              <Input prefix={<LockOutlined />} placeholder="Password" type="password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit" loading={false}>
                Okay
              </Button>
            </Form.Item>
            {error && <div>Failed to decrypt - your password might be incorrect. Please try again.</div>}
          </Form>
        </div>
      </div>
    </>
  );
};

export default UserKeyPair;
