import { z } from "zod";

const disabledSchema = z.object({
  enabled: z.literal(false),
});

const enabledSchema = z.object({
  enabled: z.literal(true),
  /** Enables the embed code script - not allowed for deployments like HKMA. */
  allowEmbedCode: z.boolean(),
  /** Whether to report tool use to HubSpot - not wanted on dev Hub. */
  allowToolTracking: z.boolean(),
  pipeline: z.object({
    id: z.string(),
    stage: z.string(),
  }),
  portalId: z.string().optional(),
  toolUsageFormId: z.string().optional(),
});

export const schema = z.discriminatedUnion("enabled", [disabledSchema, enabledSchema]);
