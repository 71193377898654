import { getRiskBandsMode } from "@/features/branding";
import { haloFacadesApi } from "@/store/services/facades/endpoints/halo";
import { simpleFacadesApi } from "@/store/services/facades/endpoints/simple";

export const getPortfolioExportPostAction = (batch: string[]) => {
  switch (getRiskBandsMode()) {
    case "abc":
      throw new Error("ABC Portfolio Export not implemented.");

    case "halo":
      return haloFacadesApi.endpoints.postHaloBatch.initiate(batch);

    case "simple":
      return simpleFacadesApi.endpoints.postSimpleStructuralBatch.initiate(batch);
  }
};