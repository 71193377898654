import { getTheme } from "@/features/branding";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { DraggerProps } from "antd/es/upload";
import { parseZip } from "shpjs";
import { featureReduce } from "@turf/meta";
import { UploadedGeoData } from "../types";
import { RcFile } from "antd/lib/upload";
import { AllGeoJSON, Feature, featureCollection, Types } from "@turf/turf";
import { convertGeoJsonToFeatureCollection } from "../utils/geojson";
import { stripFeatureCollectionFilename } from "../utils/shapefile";

const { Dragger } = Upload;

export interface UploaderProps extends DraggerProps {
  onFailure: (error: string) => void;
  onSuccess: (response: UploadedGeoData) => void;
  filterTypes?: Types[];
}

/**
 * Accepts a GeoJSON or zipped Shapefile.
 */
export const Uploader = ({ filterTypes, onFailure, onSuccess }: UploaderProps) => {
  const theme = getTheme();

  const draggerProps: DraggerProps = {
    name: "file",
    multiple: false,
    accept: ".geojson,.zip",
    showUploadList: false,
    beforeUpload: (file) => {
      const reader = new FileReader();

      switch (file.name.split(".").pop()) {
        case "geojson":
          reader.onload = (event) => {
            const result = event.target?.result;
            processGeoJson(file, JSON.parse(result as string));
          };
          reader.readAsText(file);
          break;

        case "zip":
          file.arrayBuffer().then((data) => {
            parseZip(data).then((result) => {
              if (Array.isArray(result)) {
                switch (result.length) {
                  case 0:
                    throw new Error("Should be impossible");

                  case 1:
                    processGeoJson(file, stripFeatureCollectionFilename(result[0]));
                    break;

                  default:
                    processGeoJson(
                      file,
                      featureCollection(
                        result.reduce((list, col) => {
                          return [...list, ...(col.features as Feature[])];
                        }, [] as Feature[])
                      )
                    );
                }
                return;
              }
              processGeoJson(file, result as AllGeoJSON);
            });
          });
          break;

        default:
          throw new Error("Invalid upload type");
      }

      return false;
    },
  };

  const processGeoJson = (file: RcFile, geojson: AllGeoJSON) => {
    let invalidFeatureCount = 0;

    const features = featureReduce(
      convertGeoJsonToFeatureCollection(geojson) as any, // Annoyingly, types here aren't working.
      (last, current) => {
        if (filterTypes && filterTypes.indexOf(current.geometry.type) === -1) {
          invalidFeatureCount++;
          return last;
        }

        return [...last, current];
      },
      [] as Feature[]
    );

    if (features.length === 0) {
      const addendum = filterTypes ? ` Valid features for this tool are ${filterTypes?.join(", ")}.` : "";
      onFailure(`Error processing ${file.name}: No valid features found.${addendum}`);
      return;
    }

    onSuccess({
      data: featureCollection(features),
      name: file.name,
      size: file.size,
      stats: {
        invalidFeatureCount,
      },
    });
  };

  return (
    <Dragger {...draggerProps}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined
          style={{
            opacity: 0.666,
            color: theme.antd.token?.colorPrimary,
          }}
        />
      </p>
      <p className="ant-upload-hint">Select GeoJSON file or zipped Shapefile</p>
    </Dragger>
  );
};
