import { DetailedHTMLProps, HTMLAttributes } from "react";
import type { Props } from "../types";
import { Airtable } from "./services/Airtable";
import { MetabaseDashboard } from "./services/MetabaseDashboard";
import { Scribe } from "./services/Scribe";
import { YouTube } from "./services/YouTube";
import styles from "./index.module.scss";
import { HubspotForm } from "@/features/tracking";

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface EmbedProps extends DivProps {
  data: Props;
}

export const Embed = ({ data, ...props }: EmbedProps) => {
  return (
    <div className={styles.container} {...props}>
      {getComponent(data)}
    </div>
  );
};

const getComponent = (data: Props) => {
  switch (data.type) {
    case "airtable":
      return <Airtable {...data} />;

    case "hubspotForm":
      return <HubspotForm {...data} />;

    case "metabaseDashboard":
      return <MetabaseDashboard {...data} />;

    case "scribe":
      return <Scribe {...data} />;

    case "youtube":
      return <YouTube {...data} />;
  }
};
