import { RiskBand } from "./components/RiskBand";
import { RiskBandsList } from "./components/RiskBandsList";
import {
  defaultAbcRiskBandsConfig,
  defaultHaloRiskBandsConfig,
  defaultSimpleStructuralRiskBandsConfig,
} from "./constants/default_configs";
import { defaultInputValues } from "./constants/input_values";
import {
  abcRiskBandsSchema,
  haloRiskBandsSchema,
  simpleRiskBandsSchema,
  riskBandsSchema,
  inputValuesSchema,
} from "./schema";
import type {
  AbcRiskBandsConfig,
  AbcdeRiskValue,
  WordyRiskValue,
  HaloRiskBandsConfig,
  SimpleRiskBandsConfig,
  RiskBandsConfig,
  InputValuesConfig,
} from "./schema";
import { ensureGradedRiskValue, ensureWordyRiskValue } from "./utils/conversion";
import { getRiskBands } from "./utils/get";

export {
  abcRiskBandsSchema,
  haloRiskBandsSchema,
  simpleRiskBandsSchema,
  riskBandsSchema,
  inputValuesSchema,
  defaultInputValues,
  defaultAbcRiskBandsConfig,
  defaultHaloRiskBandsConfig,
  defaultSimpleStructuralRiskBandsConfig,
  getRiskBands,
  ensureGradedRiskValue,
  ensureWordyRiskValue,
  RiskBand,
  RiskBandsList,
};

export type {
  AbcRiskBandsConfig,
  AbcdeRiskValue,
  WordyRiskValue,
  HaloRiskBandsConfig,
  SimpleRiskBandsConfig,
  RiskBandsConfig,
  InputValuesConfig,
};
