import { getArchetypes, getConfig } from "@/features/branding";
import { FoundationType } from "@/store/services/facades/types";
import { RiskBandsAdvancedSettingsHAG } from "@/tools/risk-bands/types";
import { InputNumber, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type FoundationTypes = Record<
  FoundationType,
  {
    label: string;
    height: number;
  }
>;

const FOUNDATION_TYPES: FoundationTypes = {
  basement: {
    label: "Basement Foundation",
    height: 0.0,
  },
  slabOnGround: {
    label: "Concrete Slab on Ground",
    height: 0.1,
  },
  crawlSpace: {
    label: "Crawl Space Foundation",
    height: 0.5,
  },
  pylons: {
    label: "Poles or Pylon Foundation",
    height: 2.0,
  },
} as const;

interface RiskBandsHeightAboveGroundSelectorProps {
  archetype: string;
  heightAboveGround: RiskBandsAdvancedSettingsHAG;
  onChange: (value: string, amount: number) => void;
}

export const RiskBandsHeightAboveGroundSelector = ({
  archetype,
  heightAboveGround,
  onChange,
}: RiskBandsHeightAboveGroundSelectorProps) => {
  const { t } = useTranslation();
  const { inputConstraints } = getConfig();
  const archetypes = getArchetypes();
  const { defaultHeightAboveGround } = archetypes.find(({ value }) => value === archetype)!;

  const options = [
    {
      value: "archetype",
      label: `Default for Asset Type (${defaultHeightAboveGround}m)`,
      amount: defaultHeightAboveGround || 0,
    },
    ...Object.entries(FOUNDATION_TYPES).map(([key, value]) => ({
      value: key,
      label: `${value.label} (${value.height}m)`,
      amount: value.height,
    })),
    { value: "custom", label: "Custom value" },
  ];

  useEffect(() => {
    const option = options.find(({ value }) => value === heightAboveGround.source)!;
    if (option && "amount" in option && option.amount !== undefined) {
      onChange(option.value, option.amount);
    }
  }, [archetype]);

  const handleHeightAboveGroundChange = (value: string) => {
    if (value === "custom") {
      onChange("custom", 0);
    }

    const option = options.find((opt) => opt.value === value)!;

    if ("amount" in option && option.amount !== undefined) {
      onChange(option.value, option.amount);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Select
        aria-label="Height Above Ground Mode"
        value={heightAboveGround.source}
        onChange={handleHeightAboveGroundChange}
        placeholder="Select a preset height above ground"
        options={options}
      />

      {heightAboveGround.source === "custom" && (
        <InputNumber
          aria-label="Height Above Ground"
          onChange={(value) => onChange("custom", value || 0)}
          addonAfter={t("terms:units.metres")}
          min={inputConstraints.floorHeight.min}
          max={inputConstraints.floorHeight.max}
          value={heightAboveGround.amount}
          style={{ marginLeft: ".5rem" }}
        />
      )}
    </div>
  );
};
