import { RemoteJobTableHeader, RemoteJobTableHeaderProps } from "@/features/remote_jobs";
import { useGetCurrentPortfolioExportUsageQuery } from "@/store/services/supabase";
import { PortfolioExportNewRunModal } from "@/tools/aggregate/portfolio-export/components/PortfolioExportNewRunModal";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { FC } from "react";

/**
 * Header for the Portfolio Export tool's table.
 */
export const PortfolioExportTableHeader = (props: RemoteJobTableHeaderProps<PortfolioExportJobSchema>) => {
  const { data } = useGetCurrentPortfolioExportUsageQuery();

  return (
    <RemoteJobTableHeader
      {...props}
      newRunModalComponent={PortfolioExportNewRunModal as FC}
      requestNotification={true}
      disableNewRun={!data?.newRun.enabled || false}
    />
  );
};
