import { defaultAbcArchetypes } from "./archetypes/abc";
import { defaultHaloArchetypes } from "./archetypes/halo";
import { defaultSimpleStructuralArchetypes } from "./archetypes/structural";
import { AbcRiskBandsConfig, HaloRiskBandsConfig, SimpleRiskBandsConfig } from "../schema";

const baseConfig: Pick<AbcRiskBandsConfig, "quickBatch"> = {
  quickBatch: {
    maxAddresses: 30,
  },
};

export const defaultAbcRiskBandsConfig: AbcRiskBandsConfig = {
  ...baseConfig,
  mode: "abc",
  archetypes: defaultAbcArchetypes,
};

export const defaultHaloRiskBandsConfig: HaloRiskBandsConfig = {
  ...baseConfig,
  mode: "halo",
  archetypes: defaultHaloArchetypes,
};

export const defaultSimpleStructuralRiskBandsConfig: SimpleRiskBandsConfig = {
  ...baseConfig,
  mode: "simple",
  archetypes: defaultSimpleStructuralArchetypes,
};