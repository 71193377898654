// List of all Ana-safe hazards which we'd like to load.
// TODO: This should not be needed eventually.

import { Hazard } from "@climaterisk/facades_ts/halo_response";

export const anaHazardList = [
  "flood_riverine",
  "coastal_inundation",
  "heat",
  "forest_fire",
  "wind",
  "soil_movement",
  "freeze_thaw",
  "flood_surfacewater",
  "cyclone_wind",
  "landslip",
] as const;

export const anaHazards: Record<
  Hazard,
  {
    name: string;
    newId: string;
  }
> = {
  flood_riverine: {
    name: "Riverine Flood",
    newId: "riverineFlood",
  },
  // TODO: Remove? We were getting errors about matching inundation in incorporatedHz, had to change this.
  coastal_inundation: {
    name: "Coastal Inundation",
    newId: "coastalInundation",
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  inundation: {
    name: "Coastal Inundation",
    newId: "coastalInundation",
  },
  cyclone_wind: {
    name: "Cyclone Wind",
    newId: "cycloneWind",
  },
  heat: {
    name: "Extreme Heat",
    newId: "extremeHeat",
  },
  forest_fire: {
    name: "Forest Fire",
    newId: "forestFire",
  },
  wind: {
    name: "Extreme Wind",
    newId: "extremeWind",
  },
  soil_movement: {
    name: "Soil Movement",
    newId: "soilMovement",
  },
  freeze_thaw: {
    name: "Freeze Thaw",
    newId: "freezeThaw",
  },
  flood_surfacewater: {
    name: "Surfacewater Flood",
    newId: "surfacewaterFlooding",
  },
  landslip: {
    name: "Landslip",
    newId: "landslip",
  },
};
