/* eslint-disable @next/next/no-img-element */
import { getTheme } from "@/features/branding";
import supabase from "@/features/supabase";
import { ReloadOutlined } from "@ant-design/icons";
import { useSession } from "@supabase/auth-helpers-react";
import { Button, Card, Typography } from "antd";
import Head from "next/head";
import Link from "next/link";
import { CSSProperties, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import type { HomeDashboardItem } from "../types";
import { getDefaultHomeDashboardItems } from "../utils";
import styles from "./HomeDashboard.module.scss";

const { Title } = Typography;

export interface HomeDashboardProps {
  banner?: {
    hideOverlay?: boolean;
    image?: {
      alt: string;
      src: string;
      style?: CSSProperties;
    };
  };
  disclaimer?: ReactNode;
  customItems?: HomeDashboardItem[];
}

export const HomeDashboard = ({ banner, disclaimer, customItems }: HomeDashboardProps) => {
  const { antd } = getTheme();
  const { t } = useTranslation("tools", { keyPrefix: "meta" });
  const session = useSession();

  const items: HomeDashboardItem[] = customItems || getDefaultHomeDashboardItems(session);
  const primaryColour = antd.token?.colorPrimary || "#333333";
  const cardBg = antd.components?.Menu?.itemSelectedBg || primaryColour + "10";

  const subtitle = items.length ? t("subtitle") : t("zeroToolsAccess.subtitle");

  const handleRefreshSession = async () => {
    await supabase.auth.refreshSession();
  };

  return (
    <>
      <Head>
        <title>{t("title")}</title>
      </Head>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.imageWrapper}>
            {!banner?.hideOverlay && (
              <div className={styles.overlay} style={{ backgroundColor: primaryColour + "CC" }} />
            )}
            {!!banner?.image && <img alt={banner.image.alt} src={banner.image.src} style={banner.image.style} />}
          </div>
          <h1 className={styles.title}>{t("title")}</h1>
          <div className={styles.subtitleWrapper}>
            {subtitle.split("/n").map((text, index) => (
              <p key={index} className={styles.subtitle}>
                {text}
              </p>
            ))}
            {!items.length && (
              <Button onClick={handleRefreshSession} icon={<ReloadOutlined />} style={{ marginLeft: 32 }}>
                Refresh
              </Button>
            )}
          </div>

          {false && <Button className={styles.primaryButton}>Start Guided Tour</Button>}
        </div>

        {!!items.length && (
          <>
            <Title level={4} style={{ marginTop: 32 }}>
              Please select a tool from the menu or explore the use cases below to get started.
            </Title>
            <div className={styles.tools}>
              {items.map(({ title, icon, tool, link }, index) => (
                <Card className={styles.card} key={index}>
                  <div className={styles.cardIcon} style={{ color: primaryColour, backgroundColor: cardBg }}>
                    {icon}
                  </div>
                  <div className={styles.cardContentWrapper}>
                    <Title level={4} className={styles.cardTitle}>
                      {title}
                    </Title>
                    <Link href={link}>
                      <Button className={styles.cardButton} type="primary">
                        Start with {tool}
                      </Button>
                    </Link>
                  </div>
                </Card>
              ))}
            </div>
          </>
        )}

        {!!disclaimer && (
          <>
            <p className={styles.disclaimer}>
              <b>Disclaimer</b>: {disclaimer}
            </p>
          </>
        )}
      </div>
    </>
  );
};
