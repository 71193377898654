import { Authentication } from "../../types/authentication";

const authentication: Authentication = {
  logIn: "Log In",
  logOut: "Log Out",
  signUp: "Sign Up",
  captchaError: "Temporary error - please wait a moment and try again.",
  lockedUntilError:
    "Due to successive failed login attempts, your account is locked until {{time}}. For further assistance, please <a href=\"mailto:{{supportEmail}}?subject=account%20locked\" target=\"_blank\" rel=\"noreferrer\">contact support</a>.",
  passwordHookError:
    "Please <a href=\"mailto:{{supportEmail}}?subject=password%20hook%20error\" target=\"_blank\" rel=\"noreferrer\">contact support & quote 'password hook error'</a>.",
  profile: {
    title: "User Profile",
    name: "Name",
    email: "Email",
  },
  roles: {
    default: "User",
    admin: "Admin",
  },
};

export default authentication;
