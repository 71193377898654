import { getArchetypes } from "@/features/branding";
import styles from "@/styles/pages/quick/batch.module.scss";
import { RiskBandsBatchStep1, RiskBandsBatchStep1Refs } from "@/tools/risk-bands/components/BatchStep1";
import { RiskBandsBatchStep2 } from "@/tools/risk-bands/components/BatchStep2";
import { RiskBandsBatchStep3, RiskBandsBatchStep3Refs } from "@/tools/risk-bands/components/BatchStep3";
import { GeocodeWithResult } from "@/tools/risk-bands/components/MultipleResultsTable";
import { QuickBatchScreenTutorial } from "@/tools/risk-bands/tutorials/batch";
import { RiskBandsAdvancedSettingsValues, RiskBandsArchetype } from "@/tools/risk-bands/types";
import { Tool, ToolProps } from "@/types/tools";
import { is5xxHttpStatus } from "@/utils/http";
import { AppstoreOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Steps } from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export type UnmatchedType = {
  status: number;
  address: string;
  error: string;
};

const RiskBandsBatchTool = (props: ToolProps) => {
  const archetypes = getArchetypes();
  const { t } = useTranslation("tools", {
    keyPrefix: "riskBands.batch",
  });

  const [step1Refs, setStep1Refs] = useState<RiskBandsBatchStep1Refs>();
  const [step3Refs, setStep3Refs] = useState<RiskBandsBatchStep3Refs>();
  const newQuickBatchRef = useRef(null);

  const defaultArchetype = archetypes[0];
  const defaultBuildYear = 1980;
  const [step, setStep] = useState(0);
  const [addresses, setAddresses] = useState("");
  const [step1Output, setStep1Output] = useState<string[]>();
  const [step2Output, setStep2Output] = useState<GeocodeWithResult[]>();

  const [advancedSettings, setAdvancedSettings] = useState<RiskBandsAdvancedSettingsValues>({
    archetype: defaultArchetype.value as RiskBandsArchetype,
    buildYear: defaultBuildYear,
    heightAboveGround: {
      source: "archetype",
      amount: defaultArchetype.defaultHeightAboveGround || 0,
    },
  });
  const [prevAdvancedSettings, setPrevAdvancedSettings] = useState<RiskBandsAdvancedSettingsValues | null>(null);
  const [unmatchedAddresses, setUnmatchedAddresses] = useState<UnmatchedType[]>([]);

  const handleResetSettings = () => {
    setAdvancedSettings({
      archetype: defaultArchetype.value as RiskBandsArchetype,
      buildYear: defaultBuildYear,
      heightAboveGround: {
        source: "archetype",
        amount: defaultArchetype.defaultHeightAboveGround || 0,
      },
    });
  };

  const handleRetry = () => {
    const withoutServerErrors = unmatchedAddresses.filter((item) => !is5xxHttpStatus(item.status));
    setUnmatchedAddresses(withoutServerErrors);
    setStep(1);
  };

  const stepComponents = [
    <RiskBandsBatchStep1
      key="1"
      addresses={addresses}
      setAddresses={setAddresses}
      onResetSettings={handleResetSettings}
      onProgress={(addresses) => {
        setStep1Output(addresses);
        setStep(1);
      }}
      advancedSettings={advancedSettings}
      setAdvancedSettings={setAdvancedSettings}
      setRefs={setStep1Refs}
    />,
    <RiskBandsBatchStep2
      key="2"
      addresses={step1Output!}
      advancedSettings={advancedSettings}
      prevOutput={step2Output}
      prevUnmatchedAddresses={unmatchedAddresses}
      prevAdvancedSettings={prevAdvancedSettings}
      onError={() => setStep(0)}
      onProgress={(results, unmatched) => {
        setStep(2);
        setStep2Output(results);
        setUnmatchedAddresses(unmatched);
        setPrevAdvancedSettings(advancedSettings);
      }}
    />,
    <RiskBandsBatchStep3
      key="3"
      unmatched={unmatchedAddresses}
      results={step2Output!}
      onRetry={handleRetry}
      advancedSettings={advancedSettings}
      setRefs={setStep3Refs}
    />,
  ];

  const steps: string[] = t("steps", { returnObjects: true });

  const handleNewQuickBatch = () => {
    setStep1Output(undefined);
    setStep2Output(undefined);
    setAddresses("");
    setStep(0);
    handleResetSettings();
  };

  const handleBack = () => {
    setStep(0);
  };

  return (
    <>
      <Col span={24}>
        <div className={styles.navStepsContainer}>
          {step === 2 && (
            <div>
              <Button type="text" icon={<ArrowLeftOutlined />} onClick={handleBack}>
                Back
              </Button>
            </div>
          )}
          <Steps
            current={step}
            items={steps.map((title) => ({ title }))}
            labelPlacement="horizontal"
            className={styles.navSteps}
            size="small"
          />
          {step === 2 && (
            <div ref={newQuickBatchRef} style={{ justifySelf: "flex-end" }}>
              <Button type="text" icon={<AppstoreOutlined />} onClick={handleNewQuickBatch}>
                New Quick Batch
              </Button>
            </div>
          )}
        </div>
      </Col>
      <div className={styles.stepsContainer}>{stepComponents[step]}</div>
      <QuickBatchScreenTutorial
        onClose={() => {
          props.setIsTutorialOpen(false);
          handleNewQuickBatch();
        }}
        onChange={(step) => {
          setStep(step > 4 ? 2 : 0);
        }}
        open={props.isTutorialOpen}
        setState={(data) => {
          handleNewQuickBatch();
          setAddresses(data.addresses);
          setStep1Output(data.addresses.split("\n"));
          setStep2Output(data.results);
        }}
        refs={{
          newQuickBatch: newQuickBatchRef,
          step1: step1Refs,
          step3: step3Refs,
        }}
      />
    </>
  );
};

export default {
  id: "batch",
  category: "riskBands",
  keyPrefix: "riskBands.batch",
  icon: <AppstoreOutlined />,
  render: (props) => <RiskBandsBatchTool {...props} />,
  hasTutorial: true,
} as Tool;
