import { ensureWordyRiskValue } from "@/features/risk_bands";
import { AbcResponse, AbcResponseYear } from "@/store/services/facades/types/abc";
import { HaloResponse, HaloResponseYear } from "@/store/services/facades/types/halo";

// Dummy data for displaying ABC tour.
export const tourSingleResponseAbc: AbcResponse = {
  mode: "abc",
  metadata: {
    incorporated_hazards: ["coastal_inundation", "cyclone_wind", "flood_riverine"],
  },
  rcp85: {
    current_year: {
      year: 2024,
      rating: "A",
    },
    end_of_mortgage_term: {
      year: 2054,
      rating: "B",
    },
    end_of_century: {
      year: 2100,
      rating: "C",
    },
  },
};

const makeYearWordy = (data: AbcResponseYear): HaloResponseYear => {
  return {
    year: data.year,
    rating: data.rating && ensureWordyRiskValue(data.rating),
  };
};

const wordyCurrentYear = makeYearWordy(tourSingleResponseAbc.rcp85.current_year);
const wordyMortgageTerm = makeYearWordy(tourSingleResponseAbc.rcp85.end_of_mortgage_term);
const wordyCentury = makeYearWordy(tourSingleResponseAbc.rcp85.end_of_century);


const haloRcp: HaloResponse["rcp85"] = {
  current_year: wordyCurrentYear,
  end_of_mortgage_term: wordyMortgageTerm,
  end_of_century: wordyCentury,
  ratings: [
    wordyCurrentYear,
    wordyMortgageTerm,
    wordyMortgageTerm,
    wordyCentury,
    wordyCentury,
    wordyCentury,
  ],
  top_significant_hazards: ["coastal_inundation"],
};

// Dummy data for displaying Halo tour.
export const tourSingleResponseHalo: HaloResponse = {
  metadata: tourSingleResponseAbc.metadata,
  rcp26: haloRcp,
  rcp45: haloRcp,
  rcp60: haloRcp,
  rcp85: haloRcp,
  mode: "halo",
};