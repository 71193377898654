import { InteractiveTutorial, InteractiveTutorialProps } from "@/features/interactive_tutorial";
import { Image, TourStepProps } from "antd";

const steps: TourStepProps[] = [
  {
    title: "Click 'Analyse New Portfolio'.",
    description: (
      <>
        <p>Alternatively, download previous Portfolio Export files.</p>
        <Image
          alt="Indication to click the button titled 'Analyse New Portfolio'."
          src="/img/tours/portfolio-export/image6.png"
        />
      </>
    ),
  },

  {
    title: "Click 'Download example .csv'.",
    description: (
      <>
        <p>Enter your data into the demonstration .csv file, being careful to follow input rules.</p>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Image
            alt="Indication to click the 'Download example .csv' button to obtain a demonstration file."
            src="/img/tours/portfolio-export/image9.png"
            style={{
              height: "500px",
              width: "407px",
              margin: "0 auto",
            }}
          />
        </div>
      </>
    ),
  },

  {
    title: "Upload",
    description: (
      <>
        <p>
          Either drag and drop or select the completed .csv into &apos;<b>Select .csv file</b>&apos; area.
        </p>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Image
            alt="Indication to upload file using 'Select .csv file' box."
            src="/img/tours/portfolio-export/image9.png"
            style={{
              height: "500px",
              width: "407px",
              margin: "0 auto",
            }}
          />
        </div>
      </>
    ),
  },

  {
    title: "Confirmation",
    description: (
      <>
        <p>
          A dialogue will appear confirming your upload. Here you can re-name your portfolio export, see a total asset
          count for your uploaded portfolio file, and the asset usage available to your bank. Click &apos;
          <b>Continue</b>&apos; to run the Portfolio Export, or &apos;<b>Back</b>&apos; to exit.
        </p>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Image
            alt="Confirmation step before continuing with upload."
            src="/img/tours/portfolio-export/image8.png"
            style={{
              height: "500px",
              width: "571px",
              margin: "0 auto",
            }}
          />
        </div>
      </>
    ),
  },

  {
    title: "Processing",
    description: (
      <>
        <p>
          The Portfolio Export will begin to run, with its status changed to blue and &apos;<b>Processing</b>&apos;.
        </p>
        <p>
          To enable a notification in your browser once Portfolio Export is completed, click on the Alarm Bell icon and
          follow the security prompts on your operating system to enable notifications. A notification will appear on
          completion, as long as the tab remains open.
        </p>
        <Image
          alt="Homepage of Portfolio Export tool, with list of portfolios and the notification button highlighted."
          src="/img/tours/portfolio-export/image10.png"
        />
      </>
    ),
  },

  {
    title: "On Completion",
    description: (
      <>
        <p>Once processing is complete, the status changes to a green tick.</p>
        <Image
          alt="Homepage of Portfolio Export tool, with only portfolio in list indicating that it is complete."
          src="/img/tours/portfolio-export/image11.png"
        />
      </>
    ),
  },

  {
    title: "Summary",
    description: (
      <>
        <p>
          Click &apos;<b>Summary</b>&apos; button to download a .csv summary file that contains a whole-of-portfolio
          overview.
        </p>
        <Image
          alt="Indication to click the 'Summary' button on a complete portfolio."
          src="/img/tours/portfolio-export/image12.png"
        />
      </>
    ),
  },

  {
    title: "Asset Level",
    description: (
      <>
        <p>
          Click &apos;<b>Asset Level</b>&apos; to download a .csv file of risk band ratings and significant hazards
          under different RCP scenarios for each asset.
        </p>
        <Image
          alt="Indication to click the 'Asset Level' button on a complete portfolio."
          src="/img/tours/portfolio-export/image13.png"
        />
      </>
    ),
  },
];

export const PortfolioExportTutorial = (props: InteractiveTutorialProps<Record<any, any>>) => {
  return <InteractiveTutorial<Record<any, any>> isWide={true} steps={steps} {...props} />;
};
