import { getCommsConfig } from "@/features/branding";
import { EmbedModal, EmbedModalProps } from "@/features/embeds";
import { useSession } from "@supabase/auth-helpers-react";

const ANONYMOUS_EMAIL = "anonymous@hub.com";

export interface CommsModalProps extends Omit<EmbedModalProps, "data"> {
  mode: "support" | "feedback";
}

export const CommsModal = ({ mode, ...props }: CommsModalProps) => {
  const userIsAnonymous = getCommsConfig().feedback.anonymousUser;
  const session = useSession();

  return (
    <EmbedModal
      destroyOnClose
      data={{
        type: "hubspotForm",
        id: mode + "-hubspot-form",
        formId: "44315115-612d-4177-ab32-368b5e79f6e3",
        onBeforeFormSubmit: (form, values) => {
          const email = userIsAnonymous ? ANONYMOUS_EMAIL : session?.user.email;
          const deployment = window.location.host;

          // Update form values.
          for (const input of form.getElementsByClassName("hs-input")) {
            switch (input.name) {
              case "mode":
                input.value = mode;
                break;

              case "email":
                input.value = email;
                break;

              case "deployment":
                input.value = deployment;
                break;
            }
          }

          // Update submission values.
          for (const value of values) {
            switch (value.name) {
              case "mode":
                value.value = mode;
                break;

              case "email":
                value.value = email;
                break;

              case "deployment":
                value.value = deployment;
                break;
            }
          }
        },
      }}
      title={mode === "support" ? "Contact Support" : "Send Feedback"}
      width="600px"
      embedHeight="auto"
      {...props}
    />
  );
};
