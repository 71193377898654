import { getConfig } from "@/features/branding";
import { downloadXlsxFile } from "@/features/custom_downloads";
import { RemoteJobActionsProps } from "@/features/remote_jobs";
import { TazoDownloadButton } from "@/features/tazo";
import { useGetOrgQuery } from "@/store/services/supabase";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { exportPortfolioSummary } from "@/tools/aggregate/portfolio-export/utils/stream";
import { useKeyPair } from "@/utils/hooks/useKeyPair";
import { CloudDownloadOutlined, DeleteOutlined, EllipsisOutlined, StopOutlined } from "@ant-design/icons";
import { useUser } from "@supabase/auth-helpers-react";
import { Button, ButtonProps, Dropdown, notification } from "antd";
import { MenuProps } from "antd/lib";
import dynamic from "next/dynamic";
import React, { useState } from "react";

const MARSH_ORG_ID = "ec94cfd6-2a66-40d2-ad48-d04702c5a0f9";

const PortfolioExportAssetLevelDownloader = dynamic(
  () => import("@/tools/aggregate/portfolio-export/components/PortfolioExportAssetLevelDownloader"),
  {
    ssr: false,
  }
);

/**
 *
 */
interface ActionButtonProps extends ButtonProps {
  isRaw?: boolean;
}

export type PortfolioExportJobActionsProps = Omit<
  RemoteJobActionsProps<PortfolioExportJobSchema>,
  "status" | "completedActions"
>;

export const PortfolioExportJobActions = (props: PortfolioExportJobActionsProps) => {
  const [notify, notificationContext] = notification.useNotification();
  const [isExporting, setIsExporting] = useState(false);
  const { data: org } = useGetOrgQuery();
  const [key] = useKeyPair();
  const user = useUser();

  /**
   * Temporary restriction to avoid everyone getting TAZO before it's tested.
   */
  const canAccessTazo = (): boolean => {
    const { allowTazo } = getConfig().portfolioExport.assetLevelDeepDive;
    const userIsTcrg = !!user?.email?.endsWith("@theclimateriskgroup.com");
    const userIsMarsh = org?.id === MARSH_ORG_ID;
    return allowTazo && (userIsTcrg || userIsMarsh);
  };

  const exportSummary = async () => {
    setIsExporting(true);
    if (!key.user) return;

    try {
      const buffer = await exportPortfolioSummary(props.schema.id, props.schema.summaryId, key.user.keyPair);
      if (buffer) {
        downloadXlsxFile(buffer, {
          filename: props.schema.name + "_summary.xlsx",
        });
      }
    } catch (err: any) {
      console.error("Error while exporting Portfolio Summary: ", err);
      notify.error({
        message: "Something went wrong",
        description: Array.isArray(err.errors)
          ? err.errors.join(" ")
          : "Please try again or contact your administrator",
      });
    }
    setIsExporting(false);
  };

  const buttons: (ActionButtonProps | undefined)[] = [];

  switch (props.schema.status) {
    case "completed_with_errors":
    case "completed":
      buttons.push(
        {
          loading: isExporting,
          disabled: isExporting,
          icon: <CloudDownloadOutlined />,
          children: "Summary",
          onClick: exportSummary,
        },
        {
          children: canAccessTazo() ? (
            <TazoDownloadButton schema={props.schema} icon={<CloudDownloadOutlined />} size="small">
              Asset Level
            </TazoDownloadButton>
          ) : (
            <PortfolioExportAssetLevelDownloader schema={props.schema} />
          ),
          isRaw: true,
        }
      );
      break;

    case "error":
      buttons.push({
        icon: <DeleteOutlined />,
        children: "Delete",
        onClick: () => props.onDeleteJob(),
      });
      break;

    default:
      buttons.push({
        icon: <StopOutlined />,
        children: "Cancel",
        onClick: () => props.onDeleteJob(),
      });
  }

  const dropdownMenuItems: MenuProps["items"] = [
    { key: "delete-portfolio", label: "Delete", onClick: props.onDeleteJob, icon: <DeleteOutlined /> },
  ];

  return (
    <div style={{ display: "flex", gap: "0.25rem", alignItems: "center", fontWeight: 500 }}>
      {notificationContext}
      {buttons.map((button, index) => {
        if (!button) {
          return undefined;
        }

        if (button.isRaw) {
          return <React.Fragment key={index}>{button.children}</React.Fragment>;
        }

        return <Button key={index} size="small" style={{ fontWeight: "inherit" }} {...button} />;
      })}
      <Dropdown
        trigger={["click"]}
        disabled={props.schema.status !== "completed"}
        menu={{ items: dropdownMenuItems }}
        overlayStyle={{ width: 160 }}
      >
        <Button size="small" icon={<EllipsisOutlined rotate={90} />} style={{ fontWeight: "inherit" }} type="text" />
      </Dropdown>
    </div>
  );
};
