import { useGetCurrentPortfolioExportUsageQuery } from "@/store/services/supabase";
import { Alert, Button } from "antd";
import { getConfig } from "@/features/branding";
import { ArrowRightOutlined } from "@ant-design/icons";

const DetailsButton = () => {
  const { termsOfService } = getConfig().featureSet.docs.legal;

  return (
    <a href={termsOfService} target="_blank" rel="noreferrer">
      <Button size="small" type="text">
        Details {<ArrowRightOutlined />}
      </Button>
    </a>
  );
};

const PortfolioExportFairUseWarning = () => {
  const { data } = useGetCurrentPortfolioExportUsageQuery();

  if (!data) {
    return <></>;
  }

  const remainingAssets = data.quota - data.usage;

  return (
    <>
      {data.newRun.warning === "limit" && (
        <Alert
          message={
            <div>
              Your organisation has used <strong>{data.percent_used}%</strong> of your allocation. You have{" "}
              <strong>{remainingAssets} assets</strong> remaining. Please contact your administrator.
            </div>
          }
          type="warning"
          style={{ marginTop: "16px" }}
          showIcon
          action={<DetailsButton />}
        />
      )}
      {data.newRun.warning === "error" && (
        <Alert
          message={
            <div>
              Your organisation has used <strong>{data.percent_used}%</strong> of your allocation. You are{" "}
              <strong>{-remainingAssets} assets over your limit</strong>. Please contact your administrator.
            </div>
          }
          type="error"
          style={{ marginTop: "16px" }}
          showIcon
          action={<DetailsButton />}
        />
      )}
    </>
  );
};

export default PortfolioExportFairUseWarning;
