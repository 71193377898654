import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";

/**
 * Button formatted to be a proper download link.
 */
export const DownloadButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button {...props}>
      <CloudDownloadOutlined />
      {children}
    </Button>
  );
};
