import { Col, Row } from "antd";
import { FC, ReactNode } from "react";
import { JobSchema } from "../types/JobSchema";
import { JobTableBody } from "./JobTableBody";
import { JobTableHeader } from "./JobTableHeader";

export interface JobTableProps<T extends JobSchema> {
  jobSchemas: Required<T>[];
  onRunStart: (schema: T) => void;
  setJobSchemas: (schemas: Required<T>[]) => void;
  children?: ReactNode;
  headerComponent?: FC;
}

export const JobTable = <T extends JobSchema>(props: JobTableProps<T>) => {
  const Header = props.headerComponent || JobTableHeader;

  return (
    <Row className="text-left mb-8 w-full">
      <Col span={24}>
        <Header onRunStart={props.onRunStart} />
      </Col>
      <Col span={24}>
        {props.children || (
          <JobTableBody
            jobSchemas={props.jobSchemas}
            renderers={{
              actions: (record) => <>Implement actions: {record.name}</>,
              jobInformation: (record) => <>Implement information: {record.name}</>,
              status: (record) => <>Implement status: {record.name}</>,
            }}
          />
        )}
      </Col>
    </Row>
  );
};
