import { Button, Modal, ModalProps, Typography } from "antd";
import { useEffect, useState } from "react";

const { Title } = Typography;

export const BlazeSettingsModal = (props: ModalProps) => {
  const [notificationSupport, setNotificationSupport] = useState<"none" | "default" | "denied" | "granted">("none");

  useEffect(() => {
    // If no notification support is detected at all, use "none".
    setNotificationSupport(typeof Notification === "undefined" ? "none" : Notification.permission);
  }, []);

  const requestPermission = () => {
    Notification.requestPermission(() => {
      setNotificationSupport(Notification.permission);
    });
  };

  return (
    <Modal title="Settings" {...props}>
      <Title level={4}>Notifications</Title>
      {notificationSupport === "none" && <>Your browser does not support notifications.</>}
      {notificationSupport === "default" && <Button onClick={requestPermission}>Enable Notifications</Button>}
      {notificationSupport === "denied" && (
        <>
          You have explicitly denied browser notifications.
          <br />
          <a href="https://support.humblebundle.com/hc/en-us/articles/360008513933-Enabling-and-Disabling-Browser-Notifications-in-Various-Browsers">
            Click here for support.
          </a>
        </>
      )}
      {notificationSupport === "granted" && <>You have enabled notifications.</>}
    </Modal>
  );
};
