import { ExternalLink } from "@/components/buttons/ExternalLink";
import { CommsModalController, CommsModalControllerMode } from "@/components/overlays/CommsModalController";
import { getCommsConfig, getConfig, getDocsConfig, getKbConfig } from "@/features/branding";
import { ChangelogModal } from "@/features/changelog";
import supabase from "@/features/supabase";
import { useGetOrgQuery } from "@/store/services/supabase";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const MARSH_ORG_ID = "ec94cfd6-2a66-40d2-ad48-d04702c5a0f9";
const MARSH_TICKET_REQUEST_HS_ID = "1WLrNIdtGRtK1-KtLhA6-dw4lsth";

export const HelpDropdownButton = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { data: org } = useGetOrgQuery();

  const [changelogOpen, setChangelogOpen] = useState(false);
  const [commsModalMode, setCommsModalMode] = useState<CommsModalControllerMode>("none");
  const [showAdminTutorialOption, setShowAdminTutorialOption] = useState(false);
  const { feedback, support, emailSupport, reseller } = getCommsConfig();
  const topLevelItems: ItemType[] = [];

  useEffect(() => {
    if (getDocsConfig().accountManagementTutorial.enabled) {
      supabase.rpc("is_owner").then(({ data }) => {
        setShowAdminTutorialOption(data || false);
      });
    }
  }, []);

  if (showAdminTutorialOption) {
    topLevelItems.push({
      key: "tutorials:administrative",
      label: <ExternalLink href="https://climaterisk.hk/admin-tutorial">Account Mgmt Tutorial</ExternalLink>,
    });
  }

  if (support.enabled) {
    topLevelItems.push({
      key: "comms:support",
      label: "Contact Support",
      onClick: () => setCommsModalMode("support"),
    });
  }

  if (feedback.enabled) {
    topLevelItems.push({
      key: "comms:feedback",
      label: "Send Feedback",
      onClick: () => setCommsModalMode("feedback"),
    });
  }
  if (emailSupport.enabled) {
    topLevelItems.push({
      key: "comms:emailSupport",
      label: <ExternalLink href={`mailto:${emailSupport.email}`}>Email Support</ExternalLink>,
    });
  }

  if (reseller?.ticketRequestForm.enabled) {
    if (org?.id === MARSH_ORG_ID) {
      topLevelItems.push({
        key: "comms:reseller:ticketRequestForm",
        label: (
          <ExternalLink href={`https://share.hsforms.com/${MARSH_TICKET_REQUEST_HS_ID}`}>Ticket Request</ExternalLink>
        ),
      });
    }
  }

  const buildKnowledgeBaseItems = (): ItemType[] => {
    const kb = getKbConfig();

    const items = Object.entries(kb).reduce((items, [key, { enabled }]) => {
      if (!enabled) {
        if (key === "method" && kb.method.publicDocsUrl) {
          return [
            ...items,
            {
              key: "kb:method",
              label: (
                <ExternalLink href={kb.method.publicDocsUrl}>{t("pages:knowledgeBase.method.title")}</ExternalLink>
              ),
            },
          ];
        }

        return items;
      }

      return [
        ...items,
        {
          key: `kb:${key}`,
          label: t(`pages:knowledgeBase.${key}.title`),
          onClick: () => {
            router.push(`/${key}/_`);
          },
        },
      ];
    }, [] as ItemType[]);

    return topLevelItems.length && items.length ? [{ type: "divider" }, ...items] : items;
  };

  const buildChangelogItems = (): ItemType[] => {
    if (!getDocsConfig().changeLog.enabled) {
      return [];
    }

    return [
      {
        type: "divider",
      },
      {
        key: "changelog",
        label: t("features:changeLog.title"),
        onClick: () => setChangelogOpen(true),
      },
    ];
  };

  const buildLegalItems = (): ItemType[] => {
    const { privacyPolicy, termsOfService } = getConfig().featureSet.docs.legal;

    return [
      {
        type: "divider",
      },
      {
        key: "privacyPolicy",
        label: <ExternalLink href={privacyPolicy}>{t("terms:documents.privacyPolicy")}</ExternalLink>,
      },
      {
        key: "termsOfService",
        label: <ExternalLink href={termsOfService}>{t("terms:documents.termsOfService")}</ExternalLink>,
      },
    ];
  };

  // If we have no help items to offer, just hide this.
  const items = [...topLevelItems, ...buildKnowledgeBaseItems(), ...buildLegalItems(), ...buildChangelogItems()];
  if (items.length === 0) {
    return <></>;
  }

  return (
    <div style={{ padding: 4 }}>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <Button
          icon={<QuestionCircleOutlined />}
          type="text"
          style={{ width: "100%", paddingLeft: 12, justifyContent: "flex-start" }}
        >
          Help
        </Button>
      </Dropdown>
      <CommsModalController mode={commsModalMode} onClose={() => setCommsModalMode("none")} />
      {getDocsConfig().changeLog.enabled && (
        <ChangelogModal open={changelogOpen} onCancel={() => setChangelogOpen(false)} />
      )}
    </div>
  );
};
