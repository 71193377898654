import { decryptData } from "@/features/cryptography";
import { useGetErroneousPortfolioAssetsQuery } from "@/store/services/supabase";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { KeyPairWithSalt } from "@/utils/hooks/useKeyPair/types";
import { Modal, ModalProps, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";

export interface PortfolioExportJobErrorModalProps extends ModalProps {
  schema: PortfolioExportJobSchema;
  userKey: KeyPairWithSalt;
}

type ErrorAssetType = {
  key: string;
  id: string;
  location: string;
  reason: string;
};

/**
 * Shows the information for an error in a Portfolio Export job.
 */
export const PortfolioExportJobErrorModal = ({ schema, ...props }: PortfolioExportJobErrorModalProps) => {
  const ERRORS_PER_PAGE = 100;
  const [assets, setAssets] = useState<ErrorAssetType[]>([]);
  const [page, setPage] = useState(1);

  const { data, error } = useGetErroneousPortfolioAssetsQuery(
    {
      portfolioId: schema.id,
      length: ERRORS_PER_PAGE,
      start: (page - 1) * ERRORS_PER_PAGE,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const columns: ColumnsType<ErrorAssetType> = [
    {
      title: "",
      key: "index",
      render: (text, record, index) => (page - 1) * ERRORS_PER_PAGE + index + 1,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 200,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 360,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
  ];

  useEffect(() => {
    if (!data || !props.userKey || !props.userKey.keyPair) {
      setAssets([]);
      return;
    }

    const promises = data.map(async (item) => {
      const data = JSON.parse(item.data ? await decryptData(props.userKey.keyPair, item.data) : "{}");
      const errors = JSON.parse(item.errors ? await decryptData(props.userKey.keyPair, item.errors) : "[]");

      const location = "geocoding" in data ? data.geocoding.address : `(${data.latitude}, ${data.longitude})`;

      return {
        key: data.item_id,
        id: data.item_id,
        location,
        reason: `${errors.title}. ${errors.detail}.`,
      };
    });
    Promise.all(promises).then((data) => setAssets(data));
  }, [data]);

  return (
    <Modal footer={null} title={`${schema.name} (${schema.assets.errorCount} errors)`} width={1080} {...props}>
      {error ? (
        "Could not load errors"
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={assets}
            size="small"
            style={{ paddingBlockStart: "1rem" }}
            scroll={{ x: 1000 }}
            pagination={{
              current: page,
              onChange: setPage,
              pageSize: ERRORS_PER_PAGE,
              total: schema.assets.errorCount,
              position: ["bottomCenter"],
              showSizeChanger: false,
            }}
          />
        </>
      )}
    </Modal>
  );
};
