import { TrackingConfig } from "@/features/tracking";
import { Tool, ToolsObject } from "@/types/tools";
import { AuthSession } from "@supabase/supabase-js";
import { Feature, Polygon, feature } from "@turf/turf";
import { jwtDecode } from "jwt-decode";
import { allThemes } from "./themes";
import defaultTheme from "./themes/_default/index";
import { HubTheme } from "./types";

/**
 * Returns the current theme of this instance of Hub.
 * @returns Theme of this instance.
 */
export const getTheme = (): HubTheme => {
  return typeof process !== "undefined" && process.env.NEXT_PUBLIC_THEME_NAME
    ? allThemes[process.env.NEXT_PUBLIC_THEME_NAME]
    : defaultTheme;
};

/**
 * Returns the theme configuration of aesthetic choices.
 * @returns Aesthetics configuration data.
 */
export const getAestheticsConfig = () => {
  return getTheme().meta.aesthetics;
};

/**
 * Returns the name of the Hub which is contained within the theme.
 * @returns Custom name of Hub.
 */
export const getAppName = () => {
  return getTheme().meta.name;
};

export const getCommsConfig = () => {
  return getTheme().meta.config.featureSet.comms;
};

export const getDocsConfig = () => {
  return getTheme().meta.config.featureSet.docs;
};

export const getKbConfig = () => {
  return getTheme().meta.config.featureSet.docs.knowledgeBase;
};

export const getLocaleConfig = () => {
  return getTheme().meta.config.locale;
};

/**
 * Returns the specific configuration of the current instance of Hub.
 * @returns Configuration within theme.
 */
export const getConfig = () => {
  return getTheme().meta.config;
};

/**
 * Returns the risk bands mode of the current instance.
 * @returns Current risk bands mode ("abc", "halo", "simple").
 */
export const getRiskBandsMode = () => {
  return getConfig().riskBands.mode;
};

export const getArchetypes = () => {
  return getConfig().riskBands.archetypes;
};

/**
 * Gets the Archetype, by value.
 */
export const getArchetypeByValue = (value: string) => {
  return getArchetypes().find((archetype) => archetype.value === value);
};

/**
 * Builds a feature containing the coordinate bounds, if they exist.
 * @returns Coordinate bounds as a feature, or undefined.
 */
export const getCoordinateBounds = (): Feature<Polygon> | undefined => {
  const bounds = getConfig().geocoding.coordinateRestrictions.bounds;
  return bounds ? feature(bounds) : undefined;
};

export const getDefaultInputValues = (archetypeValue = "") => {
  const { defaultInputValues } = getLocaleConfig();

  const archetype = getArchetypes().find(({ value }) => value === archetypeValue);
  if (archetype && archetype.defaultInputValues) {
    return { ...defaultInputValues, ...archetype.defaultInputValues };
  }

  return defaultInputValues;
};

export const getPages = () => {
  return getConfig().pages;
};

/**
 * Returns an array of available tools.
 * @returns All tools which are available to be used in this Hub instance.
 */
export const getAllTools = (): Tool[] => {
  return getConfig().featureSet.tools;
};


export const getAllToolsAsObject = (): ToolsObject => {
  return buildToolsObject(getAllTools());
};

/**
 * Returns the current currency used by the system.
 */
export const getCurrencyCode = () => {
  // TODO: Load this in from some form of settings once able to.
  return getLocaleConfig().defaultCurrency;
};

/**
 * Returns an array of available tools allowed for the current user.
 * @returns All tools which are available to be used for the current user.
 */
export const getTools = (session: AuthSession | null): Tool[] => {
  if (!session) return [];

  const decoded = jwtDecode(session.access_token) as AuthSession["user"];
  const toolsEnabled = decoded.user_metadata?.tools_enabled || [];

  return getConfig().featureSet.tools.filter((tool) => {
    return toolsEnabled.includes(tool.keyPrefix);
  });
};

export const getToolsAsObject = (session: AuthSession | null): ToolsObject => {
  return buildToolsObject(getTools(session));
};


export const getTrackingConfig = (): TrackingConfig => {
  return getConfig().featureSet.tracking;
};

export const getSettings = () => {
  return getTheme().meta.config.featureSet.settings;
};

const buildToolsObject = (tools: Tool[]) => {
  return tools.reduce<ToolsObject>(
    (toolsObject, tool) => {
      return {
        ...toolsObject,
        [tool.category]: [...toolsObject[tool.category], tool],
      };
    },
    {
      admin: [],
      riskBands: [],
      assetLevelDeepDive: [],
      coordinateGeneration: [],
      aggregate: [],
      dataVisualisation: [],
      marketIntelligence: [],
      structural: [],
      reference: [],
    }
  );
};