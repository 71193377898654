import styles from "@/components/buttons/AccountDropdownButton.module.scss";
import { Avatar } from "@/components/buttons/Avatar";
import { getSettings } from "@/features/branding";
import supabase from "@/features/supabase";
import { SettingOutlined } from "@ant-design/icons";
import { User } from "@supabase/auth-helpers-react";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

type AccountDropdownButtonProps = User;

export const AccountDropdownButton = (user?: AccountDropdownButtonProps) => {
  const { t } = useTranslation("authentication");
  const router = useRouter();

  if (!user) {
    return <div>Not logged in</div>;
  }

  const buildSettingsItems = (): ItemType[] => {
    // Do not show settings button if restricted.
    // TODO: Remove this when settings can be enabled for everyone (we have email invites, etc.).
    if (!getSettings().enabled) {
      return [];
    }

    return [
      {
        key: "settings",
        label: (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SettingOutlined />
            <span>Settings</span>
          </div>
        ),
        onClick: () => router.push("/settings/account/preferences"),
      },
      {
        type: "divider",
      },
    ];
  };

  const items: ItemType[] = [
    {
      key: "email",
      className: styles.userWrapper,
      label: <div className={styles.userEmail}>{user.email}</div>,
      style: {
        pointerEvents: "none",
      },
    },
    {
      type: "divider",
    },
    ...buildSettingsItems(),
    {
      key: "logout",
      onClick: async () => {
        await supabase.auth.signOut();
      },
      label: t("logOut"),
    },
  ];

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        className={styles.dropdownContainer}
        rootClassName={styles.dropdownContent}
      >
        <Button icon={<Avatar name={user.email || ""} />} type="text"></Button>
      </Dropdown>
    </>
  );
};

