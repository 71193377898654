import { ReactNode } from "react";
import styles from "./ExternalLink.module.scss";
import { Col, Row } from "antd";
import { ExportOutlined } from "@ant-design/icons";

export const ExternalLink = (props: { href: string; hideIcon?: boolean; children: ReactNode }) => {
  return (
    <a href={props.href} target="_blank" rel="noreferrer" className={styles.externalLink}>
      <Row justify="space-between">
        <Col>{props.children}</Col>
        {!props.hideIcon && (
          <Col>
            <ExportOutlined />
          </Col>
        )}
      </Row>
    </a>
  );
};
