import { Select } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { templateGroups, templates } from "../constants/templates";
import { Settings } from "../types/settings";

interface TemplateSelectorProps {
  onChange: (settings?: Settings) => void;
}

/**
 * Select a template, or the "Custom" option.
 * @returns Component.
 */
export const TemplateSelector = ({ onChange }: TemplateSelectorProps) => {
  const [id, setId] = useState("custom");

  useEffect(() => {
    const template = templates.find((template) => template.id === id);
    onChange(template ? template.settings : undefined);
  }, [id]);

  return (
    <Select value={id} onChange={setId}>
      {templateGroups.map(({ id, title }) => {
        return <Select.OptGroup key={title}>{getOptionsForGroup(id)}</Select.OptGroup>;
      })}
      <Select.Option key="custom">Custom (In Development)</Select.Option>
    </Select>
  );
};

/**
 * Get all the templates in a given template group, as a Select.Option array.
 * @param groupId - ID of group we want to match.
 * @returns All templates in a given template group.
 */
const getOptionsForGroup = (groupId: string): ReactNode[] => {
  return templates.reduce<ReactNode[]>((list, template) => {
    return template.groupId === groupId
      ? [...list, <Select.Option key={template.id}>{template.title}</Select.Option>]
      : list;
  }, []);
};
