import { Col, Image, Row, Typography } from "antd";
import styles from "./XdiAttribution.module.scss";

const { Title } = Typography;

export interface XdiAttributionProps {
  /** Mode in which to display "Powered by XDI". */
  mode: "vertical";
}

/**
 * Visual marker denoting that this web app is developed by XDI.
 * TODO: Add horizontal mode.
 */
export const XdiAttribution = ({ mode }: XdiAttributionProps) => {
  switch (mode) {
    case "vertical":
      return (
        <Row className={styles.container}>
          <Col span={24}>
            <Title level={2} className={styles.title}>
              Powered by
            </Title>
          </Col>
          <Col span={24}>
            <a href="https://xdi.systems" target="_blank" rel="noreferrer">
              <Image
                alt="XDI Logo"
                className={styles.logo}
                preview={false}
                src="/xdi-logo.png"
                width="100%"
                height="2.25rem"
              />
            </a>
          </Col>
        </Row>
      );
  }
};
