import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LibraryAsset } from "@/types/library";

export interface LibraryState {
  assets: LibraryAsset[];
  countPerPage: number;
}

const initialState: LibraryState = {
  assets: [],
  countPerPage: 20,
};

export const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    addAsset: (state, action: PayloadAction<LibraryAsset>) => {
      state.assets = [action.payload, ...state.assets];
    },
    removeAsset: (state, action: PayloadAction<string>) => {
      state.assets = state.assets.filter((asset) => {
        return asset.key !== action.payload;
      });
    },
    updateAsset: (state, action: PayloadAction<LibraryAsset>) => {
      state.assets = state.assets.map((asset) => {
        if (asset.key === action.payload.key) {
          return action.payload;
        }

        return asset;
      });
    },
  },
});

export const { addAsset, removeAsset, updateAsset } = librarySlice.actions;

export default librarySlice.reducer;
