import styles from "@/components/buttons/OrgDropdown.module.scss";
import { getAppName, getTheme } from "@/features/branding";
import supabase, { SupabaseDatabase } from "@/features/supabase";
import { CaretDownOutlined, PlusCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Image, MenuProps, Space } from "antd";
import { ItemType } from "antd/lib/menu/interface";

import Link from "next/link";
import { useEffect, useState } from "react";

type Orgs = SupabaseDatabase["public"]["Tables"]["orgs"]["Row"][];

/**
 * Will show a dropdown of multiple organisations to choose from if there are
 * multiple to choose from. Otherwise, will just show the name of the Hub.
 */
const OrgDropdown = () => {
  const [orgs, setOrgs] = useState<Orgs>([]);
  const [menuItems, setMenuItems] = useState<MenuProps["items"]>([]);
  const theme = getTheme();

  useEffect(() => {
    getOrgs();
  }, []);

  useEffect(() => {
    getMenuItems();
  }, [orgs]);

  /**
   * Retrieve and set the list of organisations from Supabase.
   */
  const getOrgs = async () => {
    const { data } = await supabase.from("orgs").select("*");
    setOrgs(data || []);
  };

  const getMenuItems = () => {
    const actions: ItemType[] = [
      {
        key: "settings",
        icon: <SettingOutlined />,
        // label: <Link href="/settings">Settings</Link>,
        // eslint-disable-next-line @next/next/no-html-link-for-pages
        label: <a href="/settings">Settings</a>,
      },
      { type: "divider" },
      {
        key: "create-org",
        icon: <PlusCircleOutlined />,
        label: "Create an Organization",
        disabled: true,
      },
    ];

    const transformData = () => orgs.map((org) => ({ key: org.id, label: org.name }));

    if (orgs.length <= 1) {
      setMenuItems([...actions]);
    } else {
      setMenuItems([...transformData(), { type: "divider" }, ...actions]);
    }
  };

  // TODO: Use the org logo instead. Retrieve from supabase (storage?)
  // NOTE: This will also mean using the org name instead of name from theme.
  const logo = theme.meta.logoIconUrl || theme.meta.logoUrl;

  const TitleWithLogo = () => (
    <Link href="/">
      <Space className={styles.link}>
        {logo && (
          <div className={styles.logoContainer}>
            <Image src={logo} alt="Logo" preview={false} />
          </div>
        )}
        <div className={styles.currentOrgName}>{getAppName()}</div>
      </Space>
    </Link>
  );

  // If we only have a single organisation to choose from, do not show dropdown.
  if (orgs.length <= 1) {
    return <TitleWithLogo />;
  }

  return (
    <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
      <Space className={styles.appName}>
        <TitleWithLogo />
        <CaretDownOutlined />
      </Space>
    </Dropdown>
  );
};

export default OrgDropdown;
