import { getTrackingConfig } from "@/features/branding";
import PlausibleProvider from "next-plausible";
import { ReactNode } from "react";

export interface HubPlausibleProviderProps {
  children: ReactNode;
}

export const HubPlausibleProvider = (props: HubPlausibleProviderProps): JSX.Element => {
  const { plausible } = getTrackingConfig();

  // Don't even add provider if Plausible tracking disabled.
  if (!plausible.enabled) {
    return <>{props.children}</>;
  }

  return (
    <PlausibleProvider enabled={true} domain={plausible.siteDomain} trackLocalhost={plausible.allowLocalTracking}>
      {props.children}
    </PlausibleProvider>
  );
};
