import { useSession, useUser } from "@supabase/auth-helpers-react";
import { Button, Col, Layout, Menu, MenuProps, Row, Space } from "antd";

import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthCheck } from "@/components/authentication/AuthCheck";
import { AccountDropdownButton } from "@/components/buttons/AccountDropdownButton";
import { HelpDropdownButton } from "@/components/buttons/HelpDropdownButton";
import OrgDropdown from "@/components/buttons/OrgDropdown";
import styles from "@/components/layouts/HubLayout.module.scss";
import { XdiAttribution } from "@/components/layouts/XdiAttribution";
import { AcceptTerms } from "@/components/overlays/AcceptTerms";
import { HubVersionMarker } from "@/components/status/HubVersionMarker";
import { getAestheticsConfig, getToolsAsObject } from "@/features/branding";
import { Tool } from "@/types/tools";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ItemType } from "antd/es/menu/interface";
import Link from "next/link";

const { Content, Sider } = Layout;

export interface HubLayoutProps {
  children: ReactNode;
  bypassAuthCheck?: boolean;
  maxContentWidth?: string;
}

/**
 * The Hub Layout with just the header bar.
 */
export const HubLayout = ({ bypassAuthCheck, children }: HubLayoutProps) => {
  const user = useUser()!;

  const layout = (
    <Layout className={styles.layout}>
      <AcceptTerms />
      <Row className={styles.branding}>
        <div className={styles.brandingContent}>
          <Space align="center">
            <OrgDropdown />
          </Space>
          {!!user && (
            <Space align="center" size={0}>
              <AccountDropdownButton {...user} />
            </Space>
          )}
        </div>
      </Row>
      <Layout className={styles.headerContent}>{children}</Layout>
    </Layout>
  );

  return bypassAuthCheck ? layout : <AuthCheck>{layout}</AuthCheck>;
};

export interface HubLayoutWithMenuProps extends HubLayoutProps {
  items: MenuProps["items"];
  onClick: (key: string) => void;
  selectedKey: string;
  hideBackLink?: boolean;
}

/**
 * The Hub Layout with a custom menu.
 */
export const HubLayoutWithMenu = ({
  items,
  onClick,
  selectedKey,
  children,
  hideBackLink = false,
  maxContentWidth = "1200px",
  ...props
}: HubLayoutWithMenuProps) => {
  const user = useUser();
  const { attribution } = getAestheticsConfig();

  // const handleRefreshSession = async () => {
  //   await supabase.auth.refreshSession();
  // };

  return (
    <HubLayout {...props}>
      <Sider className={styles.sidebar} width={256}>
        <div>
          {!hideBackLink && (
            <Link href="/">
              <div className={styles.backLinkContainer}>
                <Button block type="text" className={styles.backLink} icon={<ArrowLeftOutlined />}>
                  {user ? "Back to Tools" : "Back to Log In"}
                </Button>
              </div>
            </Link>
          )}
          <Menu
            className={styles.sidebarMenu}
            items={items}
            mode="inline"
            onClick={({ key }) => onClick(key)}
            selectedKeys={[selectedKey]}
            inlineIndent={12}
          />
        </div>
        <div>
          <HelpDropdownButton />
          {attribution === "poweredByXdi" && (
            <div className={styles.attributionContainer}>
              <XdiAttribution mode="vertical" />
            </div>
          )}
          <HubVersionMarker />
        </div>
      </Sider>
      <Col className={styles.contentContainer}>
        <Content className={styles.content} style={{ maxWidth: maxContentWidth }}>
          {children}
        </Content>
      </Col>
    </HubLayout>
  );
};

/**
 * The Hub Layout but with all available tools in the menu.
 */
export const HubLayoutWithToolMenu = ({ children, maxContentWidth }: HubLayoutProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [tools, setTools] = useState<ItemType[]>([]);

  const session = useSession();

  useEffect(() => {
    const allTools = getToolsAsObject(session);

    const items: ItemType[] = Object.keys(allTools).reduce<ItemType[]>((menuItems, _groupId) => {
      const groupId = _groupId as keyof typeof allTools;
      const group = allTools[groupId];

      if (!allTools[groupId].length) {
        return menuItems;
      }

      return [
        ...menuItems,
        {
          key: groupId,
          label: t(`tools:${groupId}.title`),
          type: "group",
          children: group.map((item: Tool) => {
            return {
              key: `/tools/${groupId}/${item.id}`,
              label: (
                <>
                  {item.icon}
                  <span>{t(`tools:${item.keyPrefix}.title`)}</span>
                </>
              ),
              // icon: item.icon,
              // is being converted to [Object object]. Revisit later
            };
          }),
        },
      ];
    }, []);
    setTools(items);
  }, [session]);

  const onClick = (key: string) => {
    if (key !== router.pathname) {
      router.push(key);
    }
  };

  return (
    <HubLayoutWithMenu
      items={tools}
      onClick={onClick}
      selectedKey={router.asPath}
      hideBackLink={true}
      maxContentWidth={maxContentWidth}
    >
      {children}
    </HubLayoutWithMenu>
  );
};
