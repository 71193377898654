import { HacsJobSchema } from "@/features/hacs";
import { RemoteJobTableHeader } from "@/features/remote_jobs";
import { BlazeNewRunModal } from "@/tools/aggregate/blaze/components/BlazeNewRunModal";
import { BlazeSettingsModal } from "@/tools/aggregate/blaze/components/BlazeSettingsModal";
import { SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FC, useState } from "react";
import { v4 } from "uuid";

interface BlazeJobTableHeaderProps {
  onRunStart: (schema: HacsJobSchema) => void;
}

export const BlazeJobTableHeader = ({ onRunStart }: BlazeJobTableHeaderProps) => {
  const [settingsKey, setSettingsKey] = useState<string>();

  const openSettings = () => {
    setSettingsKey(v4());
  };

  return (
    <RemoteJobTableHeader<HacsJobSchema> newRunModalComponent={BlazeNewRunModal as FC} onRunStart={onRunStart}>
      <Button onClick={openSettings}>
        <SettingOutlined /> Settings
      </Button>
      <BlazeSettingsModal footer={<></>} key={settingsKey} open={!!settingsKey} onCancel={() => setSettingsKey("")} />
    </RemoteJobTableHeader>
  );
};
