import { ProcessStatusTag } from "@/components/status/ProcessStatusTag";
import { HacsJobSchema } from "@/features/hacs";
import { Col, Row } from "antd";
import styles from "./BlazeJobStatus.module.scss";

interface BlazeJobStatusProps {
  record: Required<HacsJobSchema>;
}

export const BlazeJobStatus = ({ record }: BlazeJobStatusProps) => {
  const result = record.lastPollResult!;

  return (
    <Col span={24}>
      <Row>
        <ProcessStatusTag
          className={styles.status}
          customText={
            result.status === "running" && result.files
              ? `${result.files.processed} / ${result.files.total}`
              : undefined
          }
          status={result.status}
        />
      </Row>
    </Col>
  );
};
