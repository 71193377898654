import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Timeline, TimelineProps } from "antd";

export interface SteppedTimelineProps extends TimelineProps {
  current: number;
  steps: string[];
}

export const SteppedTimeline = ({ current, steps, ...props }: SteppedTimelineProps) => {
  return (
    <Timeline
      className="text-left w-full"
      mode="alternate"
      items={steps.slice(0, current).map((children, key) => {
        return { key, children };
      })}
      pending={current < steps.length && steps[current]}
      pendingDot={<SteppedTimelineIndicator />}
      {...props}
    />
  );
};

const SteppedTimelineIndicator = () => {
  return <Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} />} />;
};
