import { ContextualInfoButton } from "@/components/buttons/ContextualInfoButton";
import { anaHazards } from "@/lib/crg_apis/constants/hazards";
import { AnaHazardId } from "@/store/services/facades/types";
import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { List } from "antd";
import { ListGridType } from "antd/es/list";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SignificantHazards.module.scss";

export interface SignificantHazardsProps {
  incorporatedHz: AnaHazardId[];
  significantHz: AnaHazardId[];
  grid?: Omit<ListGridType, "gutter">;
  style?: CSSProperties;
}

export const SignificantHazards = ({ incorporatedHz, significantHz, grid = {} }: SignificantHazardsProps) => {
  const { t } = useTranslation("terms");

  const dataSource = incorporatedHz
    .map((hz) => ({
      name: t(`hazards.${anaHazards[hz].newId}`),
      significant: significantHz.includes(hz),
    }))
    .sort((a, b) => Number(b.significant) - Number(a.significant));

  return (
    <>
      {dataSource && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div>
              <div className={styles.label}>
                Significant Hazards <ContextualInfoButton id="significantHazards" />
              </div>
            </div>
            <List
              size="small"
              className={styles.listContainer}
              dataSource={dataSource}
              grid={{ gutter: 8, ...grid }}
              renderItem={(item) => (
                <>
                  {item.significant ? (
                    <List.Item className={styles.hazardSignificant}>
                      <WarningOutlined />
                      <span>{item.name}</span>
                    </List.Item>
                  ) : (
                    <List.Item className={styles.hazard}>
                      <CloseOutlined />
                      <span>{item.name}</span>
                    </List.Item>
                  )}
                </>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};
