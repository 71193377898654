import {
  generateNewUserKeyPairs,
  unwrapEncryptedKeyPair,
  addKeyInSessionStorage,
  getKeyFromSessionStorage,
  encryptData,
  decryptData,
  importKey,
  exportKey,
} from "./functions/users";
import { checkSupport as checkCryptographySupport } from "./utils/support";
import UserPasswordInput from "./components/UserPasswordInput";

export {
  // functions/users.ts
  generateNewUserKeyPairs,
  unwrapEncryptedKeyPair,
  addKeyInSessionStorage,
  getKeyFromSessionStorage,
  encryptData,
  decryptData,
  importKey,
  exportKey,

  // utils/support.ts
  checkCryptographySupport,
  UserPasswordInput,
};
