import { getArchetypes } from "@/features/branding";
import { PortfolioExportIdFactory } from "@/tools/aggregate/portfolio-export/classes/PortfolioExportIdFactory";
import { parsePortfolioExportUploadLine } from "@/tools/aggregate/portfolio-export/utils/parse";

/**
 * Transform the Portfolio Export upload into Supabase upload format.
 */
export class PortfolioExportSupabaseTransformStream extends TransformStream {
  constructor(idFactory: PortfolioExportIdFactory) {
    /** Helper to decode chunks. */
    const decoder = new TextDecoder();
    /**  */
    let header = "";
    /**  */
    let lastTail = "";

    /** Generator of sequential indexes. */
    const indexGenerator = (function* () {
      let index = 0;

      while (true) {
        yield index++;
      }
    })();

    /**
     * Converts a line into the format we'd like to send to Supabase and Batch.
     * @param line -
     * @returns
     */
    const convertLine = async (line: string) => {
      const archetypes = getArchetypes();
      const parsedLine = parsePortfolioExportUploadLine(line, header);

      const defaultArchetype = archetypes.find(({ value }) => value === "HighRiseFlat")!;
      const defaultBuildYear = 1980;

      const id = await idFactory.get(indexGenerator.next().value);

      /** Strictly ensures that no null values end up in the request. */
      const getValue = (key: string): any => {
        if (key in parsedLine) {
          const value = parsedLine[key as keyof typeof parsedLine];
          return value === null || value === undefined ? undefined : value;
        }

        return undefined;
      };

      // TODO the batch upload process is untested
      const data: Record<string, any> = {
        item_id: id,
        build_year: getValue("Build Year (optional)") || defaultBuildYear,
      };

      // If we have coordinates, Façades only needs those. Otherwise send address.
      const latitude = getValue("Latitude (filled in via address if not supplied)");
      if (latitude === undefined) {
        data.geocoding = {
          address: getValue("Address (geocoded to provide Latitude/Longitude if they are not supplied)"),
        };
      } else {
        data.coordinates = {
          latitude,
          longitude: getValue("Longitude (filled in via address if not supplied)"),
        };
      }

      const archetype = archetypes.find(({ name }) => name === getValue("Archetype (optional)")) || defaultArchetype;
      data.archetype = archetype.value;

      data.userSuppliedId = getValue("ID (optional)");

      const providedHeightAboveGround = parsedLine["Floor height above ground (metres, optional)"];
      data["thresholds"] = {
        flood: {
          height_above_ground:
            typeof providedHeightAboveGround !== "number"
              ? archetype.defaultHeightAboveGround || 0
              : providedHeightAboveGround,
        },
      };

      return {
        id,
        data: JSON.stringify(data),
      };
    };

    super({
      transform: async (chunk, controller) => {
        const lines = decoder.decode(chunk).split("\n");

        lines.forEach(async (line, index) => {
          switch (index) {
            case 0:
              // If we don't yet have a header, we can assume this is the first
              // line and store it as such.
              if (!header) {
                header = line;
                break;
              }

              controller.enqueue(await convertLine(lastTail + line));
              break;

            case lines.length - 1:
              lastTail = line;
              break;

            default:
              controller.enqueue(await convertLine(line));
          }
        });
      },
      flush: async (controller) => {
        // Send the very final line.
        if (lastTail) {
          controller.enqueue(await convertLine(lastTail));
        }

        header = "";
        controller.terminate();
      },
    });
  }
}
