import { ContextualInfoButtonWithText } from "@/components/buttons/ContextualInfoButton";
import { Loading } from "@/components/overlays/Loading";
import { RiskBandsList } from "@/features/risk_bands";
import { SignificantHazards } from "@/components/status/SignificantHazards";
import { getRiskBandsMode } from "@/features/branding";
import { logToolUsage } from "@/features/tracking";
import { LatLng } from "@/lib/crg_apis/types/latlng";
import { RiskBandsRequest, RiskBandsResponse, RiskBandsResponseSingleRcp } from "@/store/services/facades/types";
import { RiskBandsAdvancedSettingsValues } from "@/tools/risk-bands/types";
import { Rcp, rcpValues } from "@/types/rcp";
import { ArrowLeftOutlined, CompressOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Select, Typography, Watermark, notification } from "antd";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SingleResultModal.module.scss";
import { downloadRiskBandsCsv } from "@/tools/risk-bands/utils/download";
import { GeocodeWithResult } from "@/tools/risk-bands/components/MultipleResultsTable";
import { getRiskBands } from "@/features/risk_bands";

const { Title } = Typography;

interface SingleResultModalProps {
  address: string;
  advancedSettings: RiskBandsAdvancedSettingsValues;
  onClose: () => void;
  onReset: () => void;
  position: LatLng;

  customResponse?: RiskBandsResponse;
  setRefs?: (refs: SingleResultModalRefs) => void;
}

export interface SingleResultModalRefs {
  back: MutableRefObject<null>;
  downloadCsv: MutableRefObject<null>;
  rcp: MutableRefObject<null>;
}

type ScenarioOptsType = {
  value: Rcp;
  label: string;
}[];

/**
 * Modal which displays the risk bands of a single asset.
 */
export const SingleResultModal = (props: SingleResultModalProps) => {
  const [ratings, setRatings] = useState<RiskBandsResponseSingleRcp>();
  const [response, setResponse] = useState<RiskBandsResponse>();
  const [scenario, setScenario] = useState<Rcp>("rcp85");
  const { t } = useTranslation("terms", {});
  const { t: actions } = useTranslation("actions");

  const backRef = useRef(null);
  const downloadCsvRef = useRef(null);
  const rcpRef = useRef(null);

  const scenarioOpts: ScenarioOptsType = rcpValues.map((rcp) => {
    return {
      value: rcp,
      label: t(`scenarios.${rcp}.medium`),
    };
  });

  useEffect(() => {
    if (!props.setRefs) {
      return;
    }

    props.setRefs({
      back: backRef,
      downloadCsv: downloadCsvRef,
      rcp: rcpRef,
    });
  }, [backRef, downloadCsvRef, rcpRef]);

  useEffect(() => {
    if (props.customResponse) {
      setResponse(props.customResponse);
      setRatings(props.customResponse["rcp85"]);
      return;
    }

    const requestData = {
      coordinates: {
        latitude: props.position.lat,
        longitude: props.position.lng,
      },
      archetype: props.advancedSettings?.archetype,
      build_year: props.advancedSettings?.buildYear,
      thresholds: {
        flood: {
          height_above_ground: props.advancedSettings?.heightAboveGround.amount || 0,
        },
      },
    } as RiskBandsRequest;

    const promise = getRiskBands(requestData);

    promise.then(({ data, status }) => {
      if (status === "rejected") {
        notification.open({
          message: "Unable to load data. If this error persists, please contact the administrator.",
          type: "error",
        });
        props.onClose();
        return;
      }

      if (!ratings) {
        logToolUsage({
          type: "risk_band_single_asset",
          count: 1,
        });
      }

      setResponse(data);
      data && setRatings(data["rcp85"]);
    });
  }, []);

  useEffect(() => {
    if (!response) return;
    response.mode !== "abc" && setRatings(response[scenario]);
  }, [scenario, response]);

  const downloadCsv = () => {
    if (!response) {
      return;
    }

    const results: GeocodeWithResult[] = [
      {
        result: response,
        geocode: {
          name: props.address,
          confidence: 100,
          input: props.address,
          source: "Search",
          latLng: props.position,
          quality: "N/A",
        },
      },
    ];

    downloadRiskBandsCsv({ advancedSettings: props.advancedSettings, filename: "single-asset.csv", results });
  };

  const handleScenarioChange = (value: Rcp) => setScenario(value);

  return (
    <Modal
      destroyOnClose
      onCancel={props.onClose}
      footer={
        ratings && response ? (
          <>
            <Button ref={downloadCsvRef} type="primary" icon={<DownloadOutlined />} onClick={downloadCsv}>
              {actions("download.csv")}
            </Button>
          </>
        ) : null
      }
      open={true}
      width={getRiskBandsMode() === "abc" ? undefined : 840}
      closeIcon={false}
    >
      <div className={styles.navButtons}>
        <Button ref={backRef} type="text" icon={<ArrowLeftOutlined />} onClick={props.onClose}>
          Back
        </Button>
        <Button type="text" icon={<CompressOutlined />} onClick={props.onReset}>
          New Single Asset
        </Button>
      </div>

      <div className={styles.content}>
        <Row style={{ textAlign: "center" }}>
          <Col span={24}>
            <Title style={{ marginTop: 0 }}>{props.address}</Title>
          </Col>
        </Row>

        {ratings && response ? (
          <div className={styles.fadeIn} key={scenario}>
            <Row>
              <Col span={24} className={styles.ratings}>
                {!props.customResponse && <RiskBandsList ratings={ratings} />}
                {props.customResponse && (
                  <Watermark
                    gap={[48, 32]}
                    content={["EXAMPLE DATA", "For Demonstration Only"]}
                    font={{ color: "#ff0000" }}
                  >
                    <RiskBandsList ratings={ratings} />
                  </Watermark>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24} className={styles.ratingsInfo}>
                <ContextualInfoButtonWithText id="riskBands">What does this mean?</ContextualInfoButtonWithText>
              </Col>
            </Row>
            <Row>
              <Col span={24} className={styles.ratings}>
                <SignificantHazards
                  significantHz={"top_significant_hazards" in ratings ? ratings.top_significant_hazards : []}
                  incorporatedHz={response.metadata.incorporated_hazards}
                  grid={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
                />
              </Col>
            </Row>

            <div ref={rcpRef} style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
              <div className={styles.label}>RCP</div>
              <Select
                onChange={handleScenarioChange}
                options={scenarioOpts}
                defaultValue={scenario}
                style={{ maxWidth: "360px", width: "100%", textAlign: "left", gridColumn: "2/4" }}
              />
            </div>
          </div>
        ) : (
          <Row>
            <Col span={24} className={styles.ratings}>
              <Loading />
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};
