import { HubFormItem } from "@/components/inputs/HubFormItem";
import { SelectWithDescriptions } from "@/components/inputs/SelectWithDescriptions";
import { Loading } from "@/components/overlays/Loading";
import { HacsJobSchema, HacsOmDragger, HacsOmUploadData, hacsTemplates, uploadToHacs } from "@/features/hacs";
import { RemoteNewRunModal, RemoteNewRunModalProps } from "@/features/remote_jobs";
import { validateLatitude, validateLongitude } from "@/utils/geocoding";
import { Alert, Col, Row, Typography } from "antd";
import { cloneDeep } from "lodash/fp";
import { parse } from "papaparse";
import { useEffect, useState } from "react";

const { Paragraph, Title } = Typography;

const MAX_LINE_COUNT = 2000;
const MAX_LOCATIONS_PER_ELEVATION_REQUEST = 128;
const OM_FILLOUT = [
  ["Replacement cost of building", 1000000],
  ["Equivalent Land Value", 1000000],
  ["New Lifetime (years)", 50],
  ["Age", 12],
  ["Condition", "GOOD"],
  ["User Group", "jba"],
  ["Build year", 2010],
  ["Heat Threshold (return frequency)", 0.01],
  ["Floor height above ground (metres)", 0],
];

const REQUIRED_FIELDS = ["ID", "Latitude", "Longitude"];

const VALID_ARCHETYPES = [
  {
    title: "Simple House",
    value: "Simple House",
    description: "Low-rise residential urban dwelling (simplified - meaning reduced archetype elements)",
  },
  {
    title: "Simple Office",
    value: "Simple Office",
    description: "Low-rise office building (simplified - meaning reduced archetype elements)",
  },
  {
    title: "Low Rise Structure",
    value: "Low Rise Structure",
    description: "General multipurpose building with 2 to 4 floors",
  },
  {
    title: "High Rise Structure",
    value: "High Rise Structure",
    description: "General multipurpose building with 5+ floors",
  },
  {
    title: "Low Rise Office Structure",
    value: "Low Rise Office Structure",
    description: "Office building with 2 to 4 floors and workspaces",
  },
  {
    title: "High Rise Office Structure",
    value: "High Rise Office Structure",
    description: "Office building with 5+ floors",
  },
  {
    title: "Control Room",
    value: "Control Room",
    description: "A building containing control and monitoring facilities",
  },
  {
    title: "Substation",
    value: "Substation",
    description: "Electrical network node containing transformers to step voltage up or   down",
  },
  {
    title: "Wind Turbine",
    value: "Wind Turbine",
    description: "Wind Turbine for generating renewable energy",
  },
  {
    title: "Park Complex",
    value: "Park Complex",
    description: "Large multi-storey building for car parking",
  },
  {
    title: "Council Building",
    value: "Council Building",
    description: "Local council building for government or community purposes",
  },
  {
    title: "Land",
    value: "land",
    description: "Plot of land",
  },
  {
    title: "Generic Industrial",
    value: "Generic Industrial",
    description: "A generic industrial building",
  },
  {
    title: "Track Main",
    value: "Track Main",
    description: "Parallel steel rails forming a railway track",
  },
  {
    title: "Gas Distribution",
    value: "Gas Distribution",
    description: "Pipe used to transport gas",
  },
  {
    title: "Overhead",
    value: "Overhead",
    description: "Electricity pole supporting overhead power lines",
  },
  {
    title: "Berth",
    value: "Berth",
    description: "Shipping berth for loading and unloading cargo",
  },
  {
    title: "Shiploader",
    value: "Shiploader",
    description: "Machine used to load materials onto ships",
  },
];

interface DataRow {
  ID: string | number;
  Latitude: number;
  Longitude: number;
}

interface PortfolioAnalysisNewRunModalProps extends RemoteNewRunModalProps<HacsJobSchema> {
  hazards?: string[];
}

export const PortfolioAnalysisNewRunModal = ({ hazards, onRunStart, ...props }: PortfolioAnalysisNewRunModalProps) => {
  const [archetype, setArchetype] = useState("none");
  const [data, setData] = useState<HacsOmUploadData | undefined>();
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.lineCount > MAX_LINE_COUNT) {
      setData(undefined);
      setErrors([`Line count of ${data.lineCount} is longer than maximum of ${MAX_LINE_COUNT}.`]);
      return;
    }

    // Get full file and then process.
    data.file.text().then(processCsvData);
  }, [data]);

  /**
   * Validates and runs a .csv file.
   * @param rawCsvData - Direct input from the user.
   */
  const processCsvData = async (rawCsvData: string) => {
    const parseResult = parse(rawCsvData, {
      dynamicTyping: true,
      header: true,
      skipEmptyLines: true,
    });

    const { fields } = parseResult.meta;
    const table = parseResult.data as DataRow[];

    // Check we have any data.
    if (!data) {
      setErrors(["File appears to contain no data."]);
      setData(undefined);
      return;
    }

    // Ensure we have the correct number of fields and that they're correctly named.
    if (
      !fields ||
      fields.length !== REQUIRED_FIELDS.length ||
      !REQUIRED_FIELDS.every((field) => fields.includes(field))
    ) {
      setErrors(["Incorrect columns - Only 'ID', 'Latitude', 'Longitude' accepted."]);
      setData(undefined);
      return;
    }

    // Validate that all IDs are unique.
    const idSet = new Set(table.map(({ ID }) => ID));
    if (idSet.size !== table.length) {
      setErrors([`All IDs must be unique - ${table.length - idSet.size} duplicates detected.`]);
      setData(undefined);
      return;
    }

    for (const row of table) {
      // Ensure no values are null or undefined.
      for (const key of Object.keys(row)) {
        const value = (row as any)[key];

        if (value === null || value === undefined) {
          setErrors([`Row with ID '${row.ID}' is missing data for column '${key}'.`]);
          setData(undefined);
          return;
        }
      }

      // Validate latitude / longitude.
      const lat = row["Latitude"];
      const lng = row["Longitude"];

      if (!validateLatitude(lat)) {
        setErrors([`Latitude of ${lat} is outside range of -90 to 90.`]);
        setData(undefined);
        return;
      }

      if (!validateLongitude(lng)) {
        setErrors([`Longitude of ${lng} is outside range of -180 to 180.`]);
        setData(undefined);
        return;
      }
    }

    // Gather elevations.
    const service = new google.maps.ElevationService();
    const elevations: google.maps.ElevationResult[] = [];
    const duplicateTable = [...table];

    while (duplicateTable.length) {
      let response: google.maps.LocationElevationResponse | undefined = undefined;

      const locations = duplicateTable
        .splice(0, MAX_LOCATIONS_PER_ELEVATION_REQUEST)
        .map(({ Latitude, Longitude }) => ({ lat: Latitude, lng: Longitude }));

      let tryCounter = 0;
      while (!response && tryCounter < 5) {
        try {
          response = await service.getElevationForLocations({ locations });
        } catch (e) {
          tryCounter += 1;
          await new Promise((res) => setTimeout(res, 250));
        }
      }

      if (!response) {
        setErrors(["Google Elevations API error - please contact administrator."]);
        setData(undefined);
        return;
      }

      elevations.push(...response.results);
    }

    // Construct the Object Matrix.
    const headers = [
      ...fields,
      "Archetype",
      "Name",
      "Elevation above sea level (metres)",
      ...OM_FILLOUT.map((item) => item[0]),
    ];
    const extraData = OM_FILLOUT.map((item) => item[1]);

    const objectMatrix = [
      headers.join(","),
      ...table.map((row: any, index: number) => {
        return [
          row[fields[0]],
          row[fields[1]],
          row[fields[2]],
          archetype,
          row["ID"],
          elevations[index].elevation,
          ...extraData,
        ].join(",");
      }),
    ].join("\n");

    // Send run to HACS.
    const template = cloneDeep(hacsTemplates.find(({ id }) => id === "structuralFullAllRcps")!.settings);

    // Hazards overrides if structural and defined in branding.
    if (template.mode === "structural" && hazards) {
      template.files["db/Scenario.yaml"].hazards = hazards;
    }

    const response = await uploadToHacs(
      objectMatrix,
      ([a, b]) => console.log(a, b),
      {
        ...template,
        steps: [...template.steps, { type: "filter_common_portfolio_analysis" }],
      },
      {
        coreCount: 1,
        lineCount: data.lineCount,
      }
    );

    // Report that run has begun.
    onRunStart({
      name: data.file.name,
      lineCount: data.lineCount,
      folderId: response!.folderId,
      taskArn: response!.taskArn,
      startTime: new Date().getTime(),
    });
  };

  return (
    <RemoteNewRunModal
      onRunStart={() => {
        console.log("This shouldn't be called");
      }}
      title="New Porfolio Analysis"
      {...props}
    >
      <Col>
        {errors.length > 0 && (
          <Alert
            closable
            onClose={() => setErrors([])}
            style={{ marginBottom: "1rem" }}
            type="error"
            message="There were errors with your .csv file:"
            description={
              <ul style={{ marginBottom: "0" }}>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            }
          />
        )}
      </Col>
      <Col span={24}>
        {!data && (
          <HubFormItem
            label="Asset Type (Archetype)"
            style={{
              margin: ".5rem 0 1rem",
            }}
          >
            <SelectWithDescriptions
              onChange={setArchetype}
              options={[
                {
                  id: "none",
                  name: "Select an option",
                  disabled: true,
                },
                ...VALID_ARCHETYPES.map(({ title, value, description }) => {
                  return {
                    id: value,
                    name: title,
                    description,
                  };
                }),
              ]}
              style={{ width: "100%" }}
              value={archetype}
            />
          </HubFormItem>
        )}
        {archetype !== "none" && (
          <>
            <HacsOmDragger data={data} onDataChange={setData} prompt="Select .csv file" />
            {!data && (
              <>
                <Title level={5}>Input Specification</Title>
                <Row>
                  <Col span={15}>
                    This tool accepts a .csv file which contains three columns: ID, Latitude, and Longitude. Headers are
                    case sensitive. IDs must be unique. Extra columns not supported.
                  </Col>
                  <Col span={9}>
                    <Paragraph style={{ display: "inline-flex" }} code>
                      ID,Latitude,Longitude
                      <br />
                      101,-30,50
                      <br />
                      102,-35.2,178.5
                      <br />
                      103,87.67,-80
                      <br />
                      ...
                    </Paragraph>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Col>
      {data && (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "8rem",
            }}
          >
            <Loading />
          </div>
          <Title level={5} style={{ margin: "0", textAlign: "center" }}>
            Starting run...
          </Title>
        </>
      )}
    </RemoteNewRunModal>
  );
};
