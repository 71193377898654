import { HubThemePartial } from "@/features/branding";
import commonThemeBase from "./_base";
import { merge } from "lodash/fp";
import buildPortfolioAnalysisTool from "./tools/portfolio_analysis";

const commonTheme = merge(commonThemeBase, {
  meta: {
    config: {
      featureSet: {
        tools: [...(commonThemeBase.meta?.config?.featureSet?.tools || []), buildPortfolioAnalysisTool()],
      },
    },
  },
} as HubThemePartial);

export default commonTheme;
