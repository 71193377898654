import { IFrame } from "./components/IFrame";
import type { AirtableProps } from "../../types";

export const Airtable = ({ slug }: AirtableProps) => {
  const src = `https://airtable.com/embed/${slug}?backgroundColor=gray&viewControls=on`;

  return (
    <IFrame
      className="airtable-embed"
      onWheel={undefined}
      src={src}
      style={{
        background: "transparent",
        border: "1px solid #ccc",
      }}
    />
  );
};
