import { Modal, ModalProps } from "antd";
import { JobSchema } from "../types/JobSchema";

export interface JobFailModalProps<T extends JobSchema> extends ModalProps {
  schema: Required<T>;
}

export const JobFailModal = <T extends JobSchema>({ children, ...props }: JobFailModalProps<T>) => {
  return (
    <Modal footer={<></>} {...props}>
      {children}
    </Modal>
  );
};
