import { Row, Typography } from "antd";

const { Text } = Typography;

interface TextWithDescriptionProps {
  title: string;
  description?: string;
}

export const TextWithDescription = ({ title, description }: TextWithDescriptionProps) => {
  return (
    <>
      <Row>{title}</Row>
      {description && (
        <Row>
          <Text type="secondary" style={{ whiteSpace: "normal", fontSize: "90%" }}>
            {description}
          </Text>
        </Row>
      )}
    </>
  );
};
