import { Embed } from "./components";
import { Modal as EmbedModal, ModalProps as EmbedModalProps } from "./components/Modal";
import { propsSchema as embedPropsSchema } from "./types";
import type {
  Props as EmbedProps,
  AirtableProps as AirtableEmbedProps,
  HubspotFormProps as HubspotFormEmbedProps,
  MetabaseDashboardProps as MetabaseDashboardEmbedProps,
  ScribeProps as ScribeEmbedProps,
  YouTubeProps as YouTubeEmbedProps,
} from "./types";

export { Embed, EmbedModal, embedPropsSchema };

export type {
  EmbedModalProps,
  EmbedProps,
  AirtableEmbedProps,
  HubspotFormEmbedProps,
  MetabaseDashboardEmbedProps,
  ScribeEmbedProps,
  YouTubeEmbedProps,
};
