import PortfolioExportFairUseWarning from "@/tools/aggregate/portfolio-export/components/PortfolioExportFairUseWarning";
import { PortfolioExportJobInformation } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobInformation";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Alert, Input, List } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PortfolioExportJobTableBody.module.scss";

interface PortfolioExportJobTableBodyProps {
  schemas: PortfolioExportJobSchema[];
  loading: boolean;
  updateSchema: (schema: PortfolioExportJobSchema) => void;
  onDelete: (id: string) => void;
  onError: (err: string | string[], portfolioId: string) => void;
}

export const PortfolioExportJobTableBody = ({
  schemas,
  loading,
  updateSchema,
  onDelete,
  onError,
}: PortfolioExportJobTableBodyProps) => {
  const { t } = useTranslation("tools", { keyPrefix: "aggregate.portfolioExport" });
  const [search, setSearch] = useState("");
  const filteredSchema = search
    ? schemas.filter(({ name }) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    : schemas;

  const isProcessing =
    schemas.filter(({ status }) => ["processing", "pending", "provisioning", "running", "uploading"].includes(status))
      .length > 0;

  return (
    <>
      <PortfolioExportFairUseWarning />
      <div className={styles.searchContainer}>
        <Input
          prefix={<SearchOutlined style={{ height: 16 }} />}
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.searchInput}
          allowClear
        />
        {search && <div className={styles.searchTotal}>{filteredSchema.length} items</div>}
      </div>
      {isProcessing && (
        <Alert
          message={<div className={styles.processingAlertTitle}>{t("processingAlert.title")}</div>}
          description={<div className={styles.processingAlertDescription}>{t("processingAlert.blurb")}</div>}
          rootClassName={styles.processingAlert}
        />
      )}
      <div className={styles.listContainer}>
        <List
          dataSource={filteredSchema}
          itemLayout="vertical"
          pagination={false}
          rowKey="id"
          className={styles.list}
          size="large"
          loading={{
            indicator: <LoadingOutlined />,
            spinning: loading,
            size: "large",
            wrapperClassName: styles.loadingWrapper,
          }}
          renderItem={(schema) => {
            return (
              <List.Item
                // TODO: Move JobActions into this property.
                // actions={[<div key="1">Wow</div>]}
                className={styles.listItem}
              >
                <PortfolioExportJobInformation
                  onDelete={() => onDelete(schema.id)}
                  schema={schema}
                  updateSchema={updateSchema}
                  onError={onError}
                />
              </List.Item>
            );
          }}
        />
      </div>
    </>
  );
};
