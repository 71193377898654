import { JobSchema, PollResult } from "../types/jobs";
import { formatStandardDateTime } from "@/utils/datetime";
import { internalApi } from "@/store/services/internal";
import { store } from "@/store";

/**
 * Keeps track of a HACS task.
 */
export class JobMonitor {
  public lastLog = "";
  public schema: Required<JobSchema>;

  static async cancelJob(schema: JobSchema) {
    const status = schema.lastPollResult?.status;

    // We don't need to cancel the job if it is finished, or if we can't.
    if (!status || status === "completed" || status === "failed" || status === "unknown") {
      return;
    }

    // const cmd = new StopTaskCommand({
    //   cluster: "hacs-main",
    //   task: schema.taskArn,
    // });

    // Don't await since we're currently not caring about whether it stops properly or not.
    // ecsCli.send(cmd);
  }

  static async generateDownloadLink(schema: JobSchema, key = "output.zip"): Promise<string> {
    let name = schema.name.replace(".csv", `.${key.split(".").pop()}`);

    if (schema.startTime && schema.startTime > 0) {
      name = `${formatStandardDateTime(new Date(schema.startTime))}_${name}`;
    }

    const action = internalApi.endpoints.getPortfolioAnalysisDownloadLink.initiate({
      filename: name,
      folderId: schema.folderId,
      type: key.split(".")[0],
    });
    const { data } = await store.dispatch(action);
    console.log(data);

    return data ? data.link : "";
  }

  constructor(schema: JobSchema) {
    this.schema = {
      approvalRequested: false,
      lastPollResult: {
        status: "unknown",
      },
      ...schema,
    };
  }

  /**
   *
   * @returns
   */
  public async poll(): Promise<PollResult> {
    const lastStatus = this.schema.lastPollResult.status;
    if (lastStatus === "failed" || lastStatus === "completed") {
      return this.schema.lastPollResult;
    }

    const result: PollResult = await fetch("/api/portfolio-analysis/poll", {
      method: "POST",
      body: JSON.stringify(this.schema),
    }).then(async (data) => await data.json());

    if (result.status !== lastStatus) {
      switch (result.status) {
        case "failed":
        case "completed":
          this.showNotification(result.status);
      }
    }

    this.schema.lastPollResult = result;

    return result;
  }

  /**
   * Display notification for end of run.
   * @param state - State at which the run ended.
   * @returns
   */
  protected showNotification(state: "failed" | "completed"): Notification | undefined {
    if (typeof Notification === "undefined" || Notification.permission !== "granted") {
      return;
    }

    const stateText = {
      failed: "Failed",
      completed: "Succeeded",
    };

    return new Notification(`Run ${stateText[state]}`, {
      body: this.schema.name,
      icon: "/favicon.ico",
    });
  }
}
