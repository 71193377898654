import { getCmsPost } from "@/features/cms";
import { CmsPost } from "@/store/services/internal";
import { getDocsConfig } from "@/features/branding";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, ButtonProps, Drawer, Space } from "antd";
import { ReactNode, useEffect, useState } from "react";
import styles from "./ContextualInfoButton.module.scss";
import { CmsPostCategoryId } from "@/features/cms";

export interface ContextualInfoButtonProps extends ButtonProps {
  id: string;
  category?: CmsPostCategoryId;
}

/**
 * Shows a button in-context which will open a tutorial drawer. Visible only
 * when a tutorial is available.
 */
export const ContextualInfoButton = ({ id, category = "glossary", children, ...props }: ContextualInfoButtonProps) => {
  const [open, setOpen] = useState(false);
  const [post, setPost] = useState<CmsPost | undefined>(undefined);

  const showDrawer = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  useEffect(() => {
    // We don't load the data tutorials are disabled.
    if (getDocsConfig().contextualElementTutorials.enabled) {
      getCmsPost(category, id).then(setPost);
    }
  }, []);

  // We don't show this button if the information hasn't loaded.
  if (!post) {
    return <></>;
  }

  const drawerWidth = post.tags.indexOf("wide") === -1 ? 400 : 600;

  return (
    <>
      <Button className={styles.button} onClick={showDrawer} type="text" {...props}>
        {children || <InfoCircleOutlined />}
      </Button>
      <Drawer title={post.title} open={open} onClose={() => setOpen(false)} placement="right" width={drawerWidth}>
        <div
          className="prose"
          dangerouslySetInnerHTML={{
            __html:
              post.body && post.body !== "null"
                ? post.body
                : "<i>Unfortunately, this page has not yet been written.</i>",
          }}
          style={{ wordWrap: "break-word" }}
        />
      </Drawer>
    </>
  );
};

export interface ContextualInfoButtonWithTextProps extends ContextualInfoButtonProps {
  children: ReactNode;
}

export const ContextualInfoButtonWithText = ({ children, ...props }: ContextualInfoButtonWithTextProps) => {
  return (
    <Space>
      {children}
      <ContextualInfoButton {...props} />
    </Space>
  );
};
