import { useTranslation } from "react-i18next";

export const HubVersionMarker = () => {
  const { t } = useTranslation("meta");

  return (
    <div
      style={{
        width: "100%",
        padding: 4,
        paddingBottom: 6,
        fontSize: "0.85em",
        background: "var(--color-gray-100)",
        color: "var(--color-gray-500)",
        textAlign: "center",
      }}
    >
      {t("version")}
    </div>
  );
};
