/**
 * Builds a new ReadableStream out of a given string.
 * Piped through TextEncoderStream as that facilitates the most common use cases.
 * @param data - String to put into a stream.
 * @returns ReadableStream
 */
export const createStreamFromString = (data: string): ReadableStream => {
  return new ReadableStream({
    start(controller) {
      controller.enqueue(data);
      controller.close();
    },
  }).pipeThrough(new TextEncoderStream());
};


/**
 * Runs through a stream and calculates the number of lines within it.
 * @param stream - Any ReadableStream, mostly from an RcFile from AntD Upload.
 * @param options - (Optional) Configuration of this function.
 * @returns Promise<number>
 */
export const getLineCountOfStream = async (stream: ReadableStream, options?: {
  includeBlankLines?: boolean;
}): Promise<number> => {
  const decoder = new TextDecoder();
  const reader = stream.getReader();

  let lastLoopFinalSegment = "";
  let lineCount = 0;
  let isDone = false;

  // Quickly stream through file to figure out how many lines we have.
  while (!isDone) {
    const { value, done } = await reader.read();

    // Detect when the stream is complete.
    if (done) {
      if (lastLoopFinalSegment.trim() !== "" || options?.includeBlankLines) {
        lineCount += 1;
      }
      isDone = true;
      continue;
    }

    const data = decoder.decode(value);

    const segments = data.split("\n");
    segments[0] = lastLoopFinalSegment + segments[0];

    const finalSegment = segments.pop();
    lastLoopFinalSegment = finalSegment === undefined ? "" : finalSegment;

    for (const segment of segments) {
      if (segment.trim() !== "" || options?.includeBlankLines) {
        lineCount += 1;
      }
    }
  }

  return lineCount;
};