import { ContextualInfoButton } from "@/components/buttons/ContextualInfoButton";
import {
  RiskBandsAdvancedSettings,
  RiskBandsAdvancedSettingsRefs,
} from "@/components/layouts/RiskBandsAdvancedSettings";
import { getConfig } from "@/features/branding";
import { RiskBandsAdvancedSettingsValues } from "@/tools/risk-bands/types";
import { Button, Col, Divider, Input, Row, Space, Typography } from "antd";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { Text, Title } = Typography;

export interface RiskBandsBatchStep1Refs {
  addresses: MutableRefObject<null>;
  process: MutableRefObject<null>;
  advancedSettings?: RiskBandsAdvancedSettingsRefs;
}

interface RiskBandsBatchStep1Props {
  addresses: string;
  setAddresses: (addresses: string) => void;
  onResetSettings: () => void;
  onProgress: (addresses: string[]) => void;
  advancedSettings: any;
  setAdvancedSettings: Dispatch<SetStateAction<RiskBandsAdvancedSettingsValues>>;
  setRefs: (refs: RiskBandsBatchStep1Refs) => void;
}

export const RiskBandsBatchStep1 = ({
  addresses,
  setAddresses,
  onResetSettings,
  onProgress,
  advancedSettings,
  setAdvancedSettings,
  setRefs,
}: RiskBandsBatchStep1Props) => {
  const { maxAddresses } = getConfig().riskBands.quickBatch;

  const addressesRef = useRef(null);
  const processRef = useRef(null);
  const [settingsRefs, setSettingsRefs] = useState<RiskBandsAdvancedSettingsRefs | undefined>(undefined);

  const [addressCount, setAddressCount] = useState(0);
  const { t } = useTranslation();
  const { t: wt } = useTranslation("widgets", {
    keyPrefix: "textInput.addresses",
  });

  const splitAddresses = (input: string): string[] => {
    return input
      .split("\n")
      .map((address) => address.trim())
      .filter((address) => address !== "");
  };

  const handleProgress = () => {
    onProgress(splitAddresses(addresses));
  };

  useEffect(() => {
    setAddressCount(splitAddresses(addresses).length);
  }, [addresses]);

  useEffect(() => {
    setRefs({
      addresses: addressesRef,
      advancedSettings: settingsRefs,
      process: processRef,
    });
  }, [addressesRef, processRef, settingsRefs]);

  return (
    <Row style={{ width: "100%", gap: "16px" }}>
      <Col flex={1}>
        <Row ref={addressesRef}>
          <Col span={24}>
            <Title level={5} style={{ paddingTop: 0, margin: " 0 0 16px 0" }}>
              <Space>
                Enter Addresses <ContextualInfoButton category="guide" id="enteringLocationsAndAddresses" />
              </Space>
            </Title>
            <TextArea
              aria-label="Addresses"
              placeholder={wt("placeholder", { count: maxAddresses }) as string}
              onChange={(event) => setAddresses(event.target.value)}
              rows={20}
              style={{ resize: "none" }}
              value={addresses}
            />
          </Col>
          <Col span={24}>
            <Text aria-label="Addresses Status" type={addressCount > maxAddresses ? "danger" : "secondary"}>
              {wt(addressCount > maxAddresses ? "countOverLimit" : "countWithinLimit", {
                count: addressCount,
                max: maxAddresses,
              })}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col flex={0} style={{ width: "100%", maxWidth: "300px" }}>
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 0,
              marginBottom: 8,
            }}
          >
            <Title level={5} style={{ paddingTop: 0, margin: 0 }}>
              Settings
            </Title>
            <Button type="text" onClick={onResetSettings}>
              Reset
            </Button>
          </Col>
          <Col span={24}>
            <RiskBandsAdvancedSettings
              setRefs={setSettingsRefs}
              mode="multiple"
              values={advancedSettings}
              setValues={setAdvancedSettings}
            />
          </Col>
          <Col span={24}>
            <Divider style={{ marginTop: 0 }} />
            <Button
              ref={processRef}
              block
              disabled={addressCount === 0 || addressCount > maxAddresses}
              onClick={handleProgress}
              type="primary"
            >
              {t("actions:process")}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
