import { z } from "zod";

const disabledPlausibleSchema = z.object({
  enabled: z.literal(false),
});

const enabledPlausibleSchema = z.object({
  enabled: z.literal(true),
  /**
   * The "Site Domain" for the website in Plausible.
   *
   * This should be identical to the domain where Hub is hosted, but is a
   * separate property to remind us to update Plausible if changing domain.
   */
  siteDomain: z.string(),
  /**
   * Whether tracking is allowed when running on localhost.
   *
   * NOTE: This should only be enabled for the "tcrg" theme.
   */
  allowLocalTracking: z.boolean(),
});

export const plausibleSchema = z.discriminatedUnion("enabled", [disabledPlausibleSchema, enabledPlausibleSchema]);
