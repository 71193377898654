import { HacsSettings } from "@/features/hacs";
import { internalApi } from "@/store/services/internal";
import { store } from "@/store";

interface UploadSettings {
  lineCount?: number;
  coreCount?: number;
}

/**
 * Processes an upload to HACS.
 * @param body -
 * @param setProgress -
 * @param settings -
 * @returns
 */
export const upload = async (
  body: any,
  setProgress: (a: [number, number]) => void,
  settings: HacsSettings,
  uploadSettings: UploadSettings
) => {
  try {
    const action = internalApi.endpoints.uploadPortfolioAnalysis.initiate({
      body,
      settings,
      uploadSettings,
    });
    const response = await store.dispatch(action);
    const data = response.data!;
    return data;
  } catch (err) {
    console.error(err);
  }
};
