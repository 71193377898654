import { getCoordinateBounds } from "@/features/branding";
import { booleanPointInPolygon, point, polygon } from "@turf/turf";
import { LatLngLiteral } from "leaflet";

/**
 * A polygon covering the whole selectable area on the map.
 * Based on Google Maps.
 */
export const wholeWorldPolygon = polygon([
  [
    [-180, -85.1],
    [180, -85.1],
    [180, 85.1],
    [-180, 85.1],
    [-180, -85.1],
  ],
]);

/**
 * Returns whether or not a given point is within current system restrictions.
 * NOTE: If there are no coordinate bounds, this will always return true.
 * @returns Validity of given point.
 */
export const isPointWithinSystemBounds = ({ lat, lng }: LatLngLiteral): boolean => {
  const bounds = getCoordinateBounds();

  // If there are no restrictions, the point is within system bounds.
  if (!bounds) {
    return true;
  }

  return booleanPointInPolygon(point([lng, lat]), bounds);
};